import TelegramIcon from "@mui/icons-material/Telegram";
import { Box, Button, Chip, Grid, Link, Paper, Step, StepLabel, Stepper, TextField, Typography } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import { Name } from "../../themes/themes";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";
import { notification } from "../../utils-functions/notification";
import { saveTelegramChatId } from "../FacebookConnectPage/saveTelegramChatId";
import { sendTelegramMessage } from "../FacebookConnectPage/sendTelegramMessage";

function getSteps() {
    return ["Register Me in Telegram", "Get Telegram Chat Id", "Enter Verification Code"];
}

export default function RegisterTelegramStepper({ handleClose }) {
    const currentUser = useContext(AuthContext).user;
    const [telegramChatId, setTelegramChatId] = useState(currentUser.chatId);
    const [verificationCode, setVerificationCode] = useState("");
    const { user } = useContext(AuthContext);
    const [telegramBot, setTelegramBot] = useState("https://t.me/djcLeadsBot");

    const host = window.location.hostname;

    useEffect(() => {
        const loadData = async () => {
            const hostRef = doc(db, "hosts", host);
            const hostSnap = await getDoc(hostRef);
            const hostData = mapDocSnapshot(hostSnap);

            if (hostData.telegramBot) {
                setTelegramBot(hostData.telegramBot);
            }
        };
        loadData();
    }, []);

    const sendVerificationCode = () => {
        const verificationCode = parseInt(telegramChatId) * 2;
        sendTelegramMessage(
            telegramChatId,
            `Hi ${currentUser.displayName}\nYour verification code is: ${verificationCode}`
        );
    };

    const handleChange = (e) => {
        const { value } = e.target;
        setTelegramChatId(value);
        console.log(telegramChatId);
    };

    const handleChange2 = (e) => {
        const { value } = e.target;
        setVerificationCode(value);
    };

    const handleSubmit = async () => {
        if (parseInt(verificationCode) === parseInt(telegramChatId) * 2) {
            await saveTelegramChatId(telegramChatId, currentUser);
            notification("Yeah", "Subscribed to Telegram", "success");
            handleClose();
        } else {
            notification("Opps", "You have entered the wrong code. Please try again", "info");
        }
    };

    const sendMessage = (
        <React.Fragment>
            <Grid item xs={12} align="center">
                <Typography variant="subtitle1">
                    Click on the button below to go into the Telegram Bot
                </Typography>
                <Typography variant="subtitle1">Type [Register Me] to get chatId</Typography>
            </Grid>
            <Grid item xs={12} align="center">
                <Box m={1}>
                    <Link color="inherit" href={`${telegramBot}/?start=uid_${user.uid}`} target="blank">
                        <Chip
                            label="Message Telegram Bot"
                            clickable
                            color="primary"
                            onDelete={() => {}}
                            deleteIcon={<TelegramIcon />}
                            variant="outlined"
                        />
                    </Link>
                </Box>
            </Grid>
        </React.Fragment>
    );

    const enterChatId = (
        <React.Fragment>
            <Grid item xs={12} align="center">
                <Typography variant="subtitle1">Enter your Telegram Chat Id</Typography>
                <Box m={1}>
                    <TextField
                        id="telegram"
                        label="Telegram Chat Id"
                        variant="outlined"
                        value={telegramChatId}
                        onChange={handleChange}
                    />
                </Box>
            </Grid>
            <Grid item xs={12} align="center">
                <Chip
                    label="Send Verification Code"
                    clickable
                    color="primary"
                    variant="outlined"
                    onClick={sendVerificationCode}
                />
            </Grid>
        </React.Fragment>
    );

    const enterVerification = (
        <React.Fragment>
            <Grid item xs={12} align="center">
                <Typography variant="subtitle1">Enter the verification code sent to your Telegram</Typography>
                <Box m={1}>
                    <TextField
                        id="verificationCode"
                        label="Verification Code"
                        variant="outlined"
                        value={verificationCode}
                        onChange={handleChange2}
                    />
                </Box>
            </Grid>
            <Grid item xs={12} align="center">
                <Chip label="Submit" clickable color="primary" variant="outlined" onClick={handleSubmit} />
            </Grid>
        </React.Fragment>
    );

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return sendMessage;
            case 1:
                return enterChatId;
            case 2:
                return enterVerification;
            default:
                return "Unknown stepIndex";
        }
    }

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <div>
            <Paper sx={{ padding: "8px", margin: "8px" }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Paper>
            <div>
                <Paper sx={{ padding: "8px", margin: "8px" }}>
                    {activeStep === steps.length ? (
                        <div>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <Box m="8px" display="flex" gap="8px" alignContent={"center"}>
                                        <Name>All steps completed</Name>
                                        <Button onClick={handleReset}>Reset</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                    ) : (
                        <div>
                            <Grid container spacing={1}>
                                {getStepContent(activeStep)}
                                <Grid item display="flex" justifyContent={"center"} xs={12}>
                                    <Box m={"8px"}>
                                        <Button disabled={activeStep === 0} onClick={handleBack}>
                                            Back
                                        </Button>
                                        {activeStep === steps.length - 1 ? (
                                            <></>
                                        ) : (
                                            <Button variant="contained" color="primary" onClick={handleNext}>
                                                Next
                                            </Button>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                    )}
                </Paper>
            </div>
        </div>
    );
}
