import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import { addDoc, collection, deleteDoc, doc, setDoc, Timestamp } from "firebase/firestore";
import React, { useEffect } from "react";
import { arrayIsEmpty } from "../../utils-functions/arrayIsEmpty";
import { db } from "../../firebase/firebase-utils";
import toast from "react-hot-toast";
import { useConfirmation } from "../../context-utils/ConfirmationContext";

type IPrompt = {
    date: Timestamp;
    description: string;
    id?: string;
    content: string;
    type?: string;
};

export default function PromptsPage({ prompts, docName }: { prompts: IPrompt[]; docName: string }) {
    //
    const [newPrompts, setNewPrompts] = React.useState<IPrompt[]>([]);

    const handleAddPrompt = async () => {
        const newPrompt: IPrompt = {
            date: Timestamp.now(),
            description: "",
            content: "",
            type: "",
        };

        const collectionRef = collection(db, "settings", docName, "prompts");
        await addDoc(collectionRef, newPrompt);
        toast.success("Prompt added");
    };

    useEffect(() => {
        console.log(prompts);
        if (arrayIsEmpty(prompts)) {
            setNewPrompts([]);
        } else {
            setNewPrompts(prompts);
        }
    }, [prompts]);

    return (
        <>
            <Box>
                <Button size="small" variant="contained" onClick={handleAddPrompt}>
                    Add Prompt
                </Button>
                <Box my={2}>
                    <Divider />
                </Box>
            </Box>
            {newPrompts.map((prompt) => (
                <Box key={prompt.id} py={1} width={"100%"}>
                    <PromptComponent prompt={prompt} collectionName={docName} />
                </Box>
            ))}
        </>
    );
}

const PromptComponent = ({ prompt, collectionName }: { prompt: IPrompt; collectionName: string }) => {
    const [newPrompt, setNewPrompt] = React.useState<IPrompt>(prompt);

    useEffect(() => {
        setNewPrompt(prompt);
    }, [prompt]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
        const { value } = e.target;
        setNewPrompt((prev) => ({ ...prev, [field]: value }));
    };

    const handleSave = async () => {
        // Do not save if no changes
        if (JSON.stringify(newPrompt) === JSON.stringify(prompt)) return;

        try {
            if (!newPrompt.id) return;

            const docRef = doc(db, "settings", collectionName, "prompts", newPrompt.id);
            await setDoc(docRef, newPrompt, { merge: true });
            toast.success("Prompt saved");
        } catch (err: any) {
            toast.error(err.message);
        }
    };

    const confirmation = useConfirmation();
    const handleDelete = async () => {
        const response = await confirmation("Delete prompt", "Press ok to confirm deletion");
        if (response) {
            try {
                if (!newPrompt.id) return;
                const docRef = doc(db, "settings", collectionName, "prompts", newPrompt.id);
                await deleteDoc(docRef);
                toast.success("Prompt deleted");
            } catch (err: any) {
                toast.error(err.message);
            }
        }
    };

    return (
        <Box>
            <Box display="flex" alignItems={"center"} gap={1} justifyContent={"flex-start"} mb={1}>
                <Typography sx={{ width: "100px" }}>Description: </Typography>
                <TextField
                    sx={{ flex: 1 }}
                    fullWidth
                    size="small"
                    value={newPrompt.description}
                    onChange={(e) => handleChange(e as any, "description")}
                    onBlur={handleSave}
                />
            </Box>
            <Box display="flex" alignItems={"center"} gap={1} mb={1}>
                <Typography sx={{ width: "100px" }}>Content: </Typography>
                <TextField
                    sx={{ flex: 1 }}
                    fullWidth
                    multiline
                    rows={8}
                    size="small"
                    value={newPrompt.content}
                    onChange={(e) => handleChange(e as any, "content")}
                    onBlur={handleSave}
                />
            </Box>
            <Box display="flex" alignItems={"center"} gap={1} justifyContent={"flex-start"}>
                <Typography sx={{ width: "100px" }}>Type: </Typography>
                <TextField
                    sx={{ flex: 1 }}
                    fullWidth
                    size="small"
                    value={newPrompt.type || ""}
                    onChange={(e) => handleChange(e as any, "type")}
                    onBlur={handleSave}
                />
            </Box>
            <Box display="flex" alignItems={"center"} gap={1} justifyContent={"flex-end"} mt={1}>
                <Button size="small" variant="contained" onClick={handleDelete} color="error">
                    Delete Prompt
                </Button>
            </Box>
            <Box my={2}>
                <Divider
                    sx={{
                        borderBottomWidth: "2px", // Adjust thickness here
                        color: "black", // Adjust color here
                    }}
                />
            </Box>
        </Box>
    );
};
