import { Avatar, Box, Button, Icon, IconButton, Typography } from "@mui/material";
import { collection, doc, onSnapshot, orderBy, query, Timestamp } from "firebase/firestore";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import useWindowSize from "../../hooks/useWindowSize";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";
import mapSnapshotTS from "../../utils-functions/mapSnapshotTS";

import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { ItemComponent } from "./ItemComponent";
import ItemDetailsComponent from "./ItemDetailsComponent";
import PromptsPage from "./PromptsPage";
import VoicesComponent from "./VoicesComponent";

export type User = {
    id: string;
    displayName: string;
    companyIds?: string[];
};

export type Setting = {
    date: Timestamp;
    id: string;
    description: string;
    subCollectionName: string;
    title: string;
} | null;

// Define the Comment interface with expected fields
export type Comment = {
    id: string; // Firestore document ID
    text: string; // The comment text
    createdAt: Date; // The timestamp when the comment was created
    createdBy: string;
    createdByName: string;
    files?: { name: string; downloadURL: string }[];
    // Add any other fields you expect in a comment
};

export type Selection = "VIEW_CASES";

export type ICustomField = {
    label: string;
    value: string;
    type: string | number;
};

export default function SystemPage(): React.JSX.Element {
    const [settings, setSettings] = useState<Setting[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [columnWidths, setColumnWidths] = useState<number[]>([20, 40, 40]);
    const [columnDisplay, setColumnDisplay] = useState<number[]>([1, 1, 1]);
    const [currentColumn, setCurrentColumn] = useState<number>(0);

    const [currentItemId, setCurrentItemId] = useState<string>("");
    const [currentItem, setCurrentItem] = useState<Setting>(null);

    const [subItems, setSubItems] = useState<any[]>([]);

    const { user } = useContext(AuthContext) as { user: User };

    const windowSize = useWindowSize();

    const isMobile = useMemo(() => {
        if (windowSize.width) {
            return windowSize.width < 600;
        } else {
            return false;
        }
    }, [windowSize.width]);

    useEffect(() => {
        if (isMobile) {
            switch (currentColumn) {
                case 0: {
                    setColumnDisplay([1, 0, 0]);
                    setColumnWidths([100, 0, 0]);
                    break;
                }
                case 1: {
                    setColumnDisplay([0, 1, 0]);
                    setColumnWidths([0, 100, 0]);
                    break;
                }
                case 2: {
                    setColumnDisplay([0, 0, 1]);
                    setColumnWidths([0, 0, 100]);
                    break;
                }
                default: {
                    setColumnDisplay([1, 0, 0]);
                    setColumnWidths([100, 0, 0]);
                    break;
                }
            }
        } else {
            setColumnDisplay([1, 1, 1]);
            setColumnWidths([20, 40, 40]);
        }
    }, [isMobile, currentColumn]);

    // Load settings on user login
    useEffect(() => {
        if (!user) return;

        const collectionRef = collection(db, "settings");

        const q = query(collectionRef, orderBy("date", "desc"));

        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
            let settings = mapSnapshotTS<Setting>(querySnapshot);

            console.log("Settings", settings);

            setSettings(settings);
        });

        return unsubscribe;
    }, [user]);

    useEffect(() => {
        if (!currentItemId) {
            setCurrentItem(null);
            return;
        }

        const docRef = doc(db, "settings", currentItemId);
        const unsubscribe = onSnapshot(docRef, (doc: any) => {
            const data = mapDocSnapshot(doc);

            setCurrentItem(data);
            console.log("Data ", data);
        });

        return unsubscribe;
    }, [currentItemId]);

    useEffect(() => {
        if (!currentItemId || !currentItem) return; // Prevent running if there's no case selected

        const subCollectionName = currentItem.subCollectionName;
        if (!subCollectionName) {
            setSubItems([]);
            return;
        }

        const collectionRef = collection(db, "settings", currentItemId, subCollectionName);
        const q = query(collectionRef, orderBy("date", "desc")); // Ensure this field exists

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const subItems = mapSnapshotTS(snapshot);
            console.log("Sub Items", subItems);
            setSubItems(subItems);
        });

        return unsubscribe; // Cleanup subscription on unmount
    }, [currentItemId, currentItem]); // Add currentCaseId as a dependency

    return (
        <Box display="flex" width={"100vw"} height={`calc(100vh - 55px)`}>
            {/* First column */}
            <Box
                display={columnDisplay[0] ? "flex" : "none"}
                flexDirection={"column"}
                width={columnWidths[0] + "%"}
                height={"100%"}
                sx={{ borderRight: "1px solid grey" }}
            >
                <Box
                    p={1}
                    py={2}
                    width={"100%"}
                    height={"86px"}
                    sx={{ borderBottom: "1px solid grey" }}
                    display="flex"
                    alignItems={"center"}
                    gap={1}
                    justifyContent={"space-between"}
                >
                    <Box display="flex" alignItems={"center"} gap={1}>
                        <PriorityHighIcon color="error" fontSize="large" />
                        <Box>
                            <Typography variant="h5">System Settings</Typography>
                            <Typography variant="subtitle1">
                                Do not change the setting without prior knowledge what it does
                            </Typography>
                        </Box>
                    </Box>
                    <Box display="flex" alignItems={"center"} gap={1}>
                        {isMobile && (
                            <IconButton onClick={() => setCurrentColumn(1)}>
                                <Icon>settings</Icon>
                            </IconButton>
                        )}
                    </Box>
                </Box>

                <Box
                    flexGrow={1}
                    display="flex"
                    flexDirection={"column"}
                    width="100%"
                    sx={{ overflowY: "auto" }}
                    // onScroll={handleScroll} // Add the scroll handler here
                >
                    {settings.map((setting) => (
                        <Box key={setting?.id} width={"100%"}>
                            <ItemComponent
                                item={setting}
                                selected={currentItemId === setting?.id}
                                handleClick={() => {
                                    setCurrentColumn(1);
                                    setCurrentItemId(setting?.id || "");
                                    console.log(setting);
                                }}
                            />
                        </Box>
                    ))}
                </Box>
            </Box>

            {/* Second column */}
            <Box
                display={columnDisplay[1] ? "flex" : "none"}
                flexDirection={"column"}
                width={columnWidths[1] + "%"}
                height={"100%"}
                sx={{ borderRight: "1px solid grey" }}
            >
                <Box
                    display="flex"
                    alignItems={"center"}
                    gap={1}
                    p={1}
                    height={"86px"}
                    sx={{ borderBottom: "1px solid grey" }}
                    justifyContent={"space-between"}
                >
                    <Box display={"flex"} alignItems={"center"} gap={1}>
                        {isMobile && (
                            <IconButton onClick={() => setCurrentColumn(0)}>
                                <Icon>arrow_back</Icon>
                            </IconButton>
                        )}
                        <Avatar />
                        <Typography>{currentItem?.title || ""}</Typography>
                    </Box>
                    <Box>
                        {/* Button to enter third column */}
                        {isMobile && (
                            <Box display="flex" justifyContent="center" p={1}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setCurrentItemId(currentItemId);
                                        setCurrentColumn(2);
                                    }}
                                    sx={{ width: "100%" }} // Make the button full width
                                >
                                    View Details
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Box>
                <Box
                    flex={1}
                    display="flex"
                    width="100%"
                    height="100%"
                    sx={{
                        backgroundSize: "cover", // to cover the entire Box area
                        backgroundPosition: "center",
                        overflowY: "auto",
                    }}
                >
                    {currentItemId === "systemPrompts" && (
                        <Box py={1} px={2} width={"100%"}>
                            <PromptsPage prompts={subItems} docName="systemPrompts" />
                        </Box>
                    )}

                    {currentItemId === "userPrompts" && (
                        <Box py={1} px={2} width={"100%"}>
                            <PromptsPage prompts={subItems} docName="userPrompts" />
                        </Box>
                    )}

                    {currentItemId === "voiceName" && (
                        <Box py={1} px={2} width={"100%"}>
                            <VoicesComponent voices={subItems} docName="voiceName" />
                        </Box>
                    )}
                </Box>
            </Box>

            {/* Third column */}
            <Box
                display={currentItemId ? "flex" : "none"} // Check for both company and case selection
                flexDirection={"column"}
                width={columnWidths[2] + "%"}
                height={"100%"}
            >
                <Box
                    display="flex"
                    alignItems={"center"}
                    gap={1}
                    p={1}
                    height={"86px"}
                    sx={{ borderBottom: "1px solid grey" }}
                    justifyContent={"space-between"}
                >
                    {isMobile && (
                        <IconButton onClick={() => setCurrentColumn(1)}>
                            <Icon>arrow_back</Icon>
                        </IconButton>
                    )}
                    {currentItem && <Typography>{`Details for ${currentItem?.id}`}</Typography>}
                </Box>

                <Box>
                    {currentItem && (
                        <Box px={3} py={1}>
                            <ItemDetailsComponent item={currentItem} />
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
}
