import { Box, Button, Divider } from "@mui/material";
import { addDoc, collection, doc, onSnapshot, orderBy, query, setDoc } from "firebase/firestore";
import React, { useContext, useEffect } from "react";
import { IIntentionBlock } from "../../../functions/src/types/intentionType";
import { UserType } from "../../../functions/src/types/userTypes";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import mapSnapshotTS from "../../utils-functions/mapSnapshotTS";
import SubItemComponent from "./SubItemComponent";
import toast from "react-hot-toast";

export default function SubItemsComponent({
    currentItemId,
    setSubItem,
}: {
    currentItemId: string;
    setSubItem: React.Dispatch<React.SetStateAction<IIntentionBlock | null>>;
}) {
    //
    const [subItems, setSubItems] = React.useState<IIntentionBlock[]>([]);

    const { user } = useContext(AuthContext) as { user: UserType };

    const handleGoUp = async (subItem: IIntentionBlock) => {
        const index = subItems.findIndex((item) => item.id === subItem.id);
        if (index === 0) return; // If it's the first item, no "up" operation is possible.

        const newSubItems = [...subItems];
        const temp = newSubItems[index - 1];

        const updates = [];

        // Update the current item (subItem)
        updates.push(
            setDoc(
                doc(db, "users", user.id, "whatsappFlows", currentItemId, "blocks", subItem.id),
                sanitizeData({
                    orderId: temp.orderId,
                    previousBlockId: index - 2 >= 0 ? newSubItems[index - 2].id : null, // Use subItems for previousBlockId
                    nextBlockId: temp.id, // Use temp's id for nextBlockId
                }),
                { merge: true }
            )
        );

        // Update the item being swapped with (temp)
        updates.push(
            setDoc(
                doc(db, "users", user.id, "whatsappFlows", currentItemId, "blocks", temp.id),
                sanitizeData({
                    orderId: subItem.orderId,
                    previousBlockId: subItem.id, // Use subItem's id for previousBlockId
                    nextBlockId: index + 1 < newSubItems.length ? newSubItems[index + 1].id : null, // Use subItems for nextBlockId
                }),
                { merge: true }
            )
        );

        // Update the nextBlockId for the item at index - 2, if it exists
        if (index - 2 >= 0) {
            updates.push(
                setDoc(
                    doc(
                        db,
                        "users",
                        user.id,
                        "whatsappFlows",
                        currentItemId,
                        "blocks",
                        newSubItems[index - 2].id
                    ),
                    sanitizeData({
                        nextBlockId: temp.id, // Use temp's id
                    }),
                    { merge: true }
                )
            );
        }

        // Update the previousBlockId for the item at index + 1, if it exists
        if (index + 1 < newSubItems.length) {
            updates.push(
                setDoc(
                    doc(
                        db,
                        "users",
                        user.id,
                        "whatsappFlows",
                        currentItemId,
                        "blocks",
                        newSubItems[index + 1].id
                    ),
                    sanitizeData({
                        previousBlockId: temp.id, // Use temp's id
                    }),
                    { merge: true }
                )
            );
        }

        await Promise.all(updates);
    };

    const handleGoDown = async (subItem: IIntentionBlock) => {
        const index = subItems.findIndex((item) => item.id === subItem.id);
        if (index === subItems.length - 1) return; // If it's the last item, no "down" operation is possible.

        const newSubItems = [...subItems];
        const temp = newSubItems[index + 1];

        const updates = [];

        // Update the current item (subItem)
        updates.push(
            setDoc(
                doc(db, "users", user.id, "whatsappFlows", currentItemId, "blocks", subItem.id),
                sanitizeData({
                    orderId: temp.orderId,
                    previousBlockId: temp.id, // Use temp's id as previousBlockId
                    nextBlockId: index + 2 < newSubItems.length ? newSubItems[index + 2].id : null, // Use subItems for nextBlockId
                }),
                { merge: true }
            )
        );

        // Update the item being swapped with (temp)
        updates.push(
            setDoc(
                doc(db, "users", user.id, "whatsappFlows", currentItemId, "blocks", temp.id),
                sanitizeData({
                    orderId: subItem.orderId,
                    previousBlockId: index - 1 >= 0 ? newSubItems[index - 1].id : null, // Use subItems for previousBlockId
                    nextBlockId: subItem.id, // Use subItem's id as nextBlockId
                }),
                { merge: true }
            )
        );

        // Update the previousBlockId for the item at index + 2, if it exists
        if (index + 2 < newSubItems.length) {
            updates.push(
                setDoc(
                    doc(
                        db,
                        "users",
                        user.id,
                        "whatsappFlows",
                        currentItemId,
                        "blocks",
                        newSubItems[index + 2].id
                    ),
                    sanitizeData({
                        previousBlockId: subItem.id, // Use subItem's id as previousBlockId
                    }),
                    { merge: true }
                )
            );
        }

        // Update the nextBlockId for the item at index - 1, if it exists
        if (index - 1 >= 0) {
            updates.push(
                setDoc(
                    doc(
                        db,
                        "users",
                        user.id,
                        "whatsappFlows",
                        currentItemId,
                        "blocks",
                        newSubItems[index - 1].id
                    ),
                    sanitizeData({
                        nextBlockId: temp.id, // Use temp's id as nextBlockId
                    }),
                    { merge: true }
                )
            );
        }

        await Promise.all(updates);
    };

    useEffect(() => {
        if (!currentItemId) return;
        const collectionRef = collection(db, "users", user.id, "whatsappFlows", currentItemId, "blocks");
        const q = query(collectionRef, orderBy("orderId", "asc"));

        const unsubscribe = onSnapshot(q, (querySnapshot: any) => {
            const blocks = mapSnapshotTS<IIntentionBlock>(querySnapshot);
            setSubItems(blocks);
        });

        return unsubscribe;
    }, [currentItemId]);

    const handleAddIntention = async () => {
        const newIntention: IIntentionBlock = {
            date: new Date(),
            id: "",
            orderId: subItems.length,
            intentionType: "message",
            type:"BlockNode",
            previousBlockId: subItems.length ? subItems[subItems.length - 1].id : "",
            nextBlockId: "",
            title: "New Intention",
            userId: user.id,
        };

        const docRef = collection(db, "users", user.id, "whatsappFlows", currentItemId, "blocks");

        const response = await addDoc(docRef, newIntention);
        toast.success("Intention added");

        // set the last item's nextBlockId to the new item's id
        if (subItems.length) {
            const lastItem = subItems[subItems.length - 1];
            await setDoc(
                doc(db, "users", user.id, "whatsappFlows", currentItemId, "blocks", lastItem.id),
                { nextBlockId: response.id },
                { merge: true }
            );
        }
    };

    return (
        <>
            <Box>
                <Button size="small" variant="contained" onClick={handleAddIntention}>
                    Add Intention Block
                </Button>
                <Box mt={2}>
                    <Divider />
                </Box>
            </Box>
            {subItems.map((subItem) => (
                <Box key={subItem.id} py={1} width={"100%"}>
                    <SubItemComponent
                        subItem={subItem}
                        currentItemId={currentItemId}
                        handleGoDown={handleGoDown}
                        handleGoUp={handleGoUp}
                        setSubItem={setSubItem}
                    />
                </Box>
            ))}
        </>
    );
}

const sanitizeData = (data: Record<string, any>) => {
    return Object.fromEntries(Object.entries(data).filter(([_, value]) => value !== undefined));
};
