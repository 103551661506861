import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import mapSnapshot from "../../utils-functions/mapSnapshot";

export default function loadAssignedNumbers(user, setAssignedNumbers, setLoading, whatsappFlowId) {
    try {
        const collectionRef = collection(db, "whatsappFlowAssignedNumbers");
        const q = query(
            collectionRef,
            where("clientId", "==", user.clientId),
            where("whatsappFlowId", "==", whatsappFlowId), //added by wayne 22-10-24 to reduce the unwanted records by retrieving only current whatsappFlow.id
            orderBy("date", "desc")
        );
        setLoading(true);
        return onSnapshot(q, (querySnapshot) => {
            const assignedNumbers = mapSnapshot(querySnapshot);
            console.log(assignedNumbers);
            setAssignedNumbers(assignedNumbers);
            setLoading(false);
        });
    } catch (error) {
        console.log(error);
    }
}
