import EditIcon from "@mui/icons-material/Edit";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { Box, Button, Container, Grid, IconButton, Paper, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import MarkDown from "../../components/MarkDown";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import { useInput } from "../../context-utils/InputContext";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import removeSpacesAndSpecialChars from "../../functions/common-functions/removeSpacesAndSpecialChars";
import { GridContainer, GridDivider, GridFlexBox, Name, Title } from "../../themes/themes";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";
import FormSubmissionsPage from "./FormSubmissionsPage";

export default function FormAffiliatePage() {
    const { user } = useContext(AuthContext);
    const [referralCode, setReferralCode] = useState("");
    const [copySuccess, setCopySuccess] = useState("");
    const [form, setForm] = useState();

    const { formId } = useParams();

    useEffect(() => {
        if (!user) return;
        setReferralCode(user.referralCode || "");
    }, [user]);

    useEffect(() => {
        const getForm = async () => {
            const docRef = doc(db, "forms", formId);
            const snapshot = await getDoc(docRef);
            const form = mapDocSnapshot(snapshot);
            setForm(form);
        };
        getForm();
    }, [formId]);

    const input = useInput();
    const confirmation = useConfirmation();

    const handleEdit = async () => {
        const response = await input(
            "Edit referral code",
            "Enter your new referral code in one word without spacing",
            "Referral Code",
            user.referralCode || ""
        );

        if (response) {
            let newReferralCode = response.trim();
            newReferralCode = removeSpacesAndSpecialChars(newReferralCode);

            if (referralCode === newReferralCode) return;

            const collectionRef = collection(db, "users");
            const q = query(collectionRef, where("referralCode", "==", newReferralCode));
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                toast.error("Referral code already exists");
                return;
            }

            const confirmed = await confirmation(
                `Referral code: ${newReferralCode}`,
                `Changing your referral code will affect your exiting affiliate links. Confirm?`
            );

            if (confirmed) {
                const userRef = doc(db, "users", user.id);
                await updateDoc(userRef, { referralCode: newReferralCode });
                toast.success("Referral code updated successfully");
            }
        }
    };

    const hostname = window.location.hostname;

    const copyToClipboard = () => {
        const url = `https://${hostname}/form/${formId}/${user.referralCode || ""}`;
        navigator.clipboard.writeText(url).then(
            () => {
                setCopySuccess("Copied!");
                setTimeout(() => setCopySuccess(""), 3000); // Reset message after 3 seconds
            },
            () => {
                setCopySuccess("Failed to copy");
            }
        );
    };

    return (
        <>
            <Container maxWidth="xs">
                <GridContainer>
                    <Title>Form Affiliate Page</Title>
                </GridContainer>
                <GridDivider />
                <Grid item>
                    <Paper sx={{ width: "100%", padding: "8px" }}>
                        <MarkDown markdown={form?.description} />
                    </Paper>
                </Grid>
                <GridDivider />
                <GridFlexBox>
                    <Box display={"flex"} gap={"8px"}>
                        <Name>Referral Code:</Name>
                        <Typography sx={{ border: "solid 1px grey", padding: "4px" }}>
                            {referralCode}
                        </Typography>
                        {user?.referralCode ? (
                            <IconButton onClick={handleEdit}>
                                <EditIcon />
                            </IconButton>
                        ) : (
                            <Button onClick={handleEdit} variant="outlined">
                                Add Referral Code
                            </Button>
                        )}
                    </Box>
                </GridFlexBox>
                <GridDivider />
                <GridFlexBox>
                    <Name>Your form link is:</Name>
                </GridFlexBox>
                <GridFlexBox>
                    {referralCode ? (
                        <Typography
                            whiteSpace={"break-spaces"}
                            sx={{ border: "solid 1px grey", padding: "8px", wordBreak: "break-word" }}
                        >{`https://${hostname}/form/${formId}/${referralCode}`}</Typography>
                    ) : (
                        "Please add referral code"
                    )}
                </GridFlexBox>
                {referralCode && (
                    <GridFlexBox>
                        <Button size="small" startIcon={<FileCopyOutlinedIcon />} onClick={copyToClipboard}>
                            Copy
                        </Button>
                        {copySuccess && <Name color={green[500]}>{copySuccess}</Name>}
                    </GridFlexBox>
                )}

                <GridDivider />
            </Container>
            <FormSubmissionsPage form={form} />
        </>
    );
}
