import { Box, CircularProgress, Container, Pagination, TextField, Typography } from "@mui/material";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { UserType } from "../../../functions/src/types/userTypes";
import ExportToExcel from "../../components/ExportToExcel/ExportToExcel";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import convertToLowerCase from "../../functions/common-functions/convertToLowerCase";
import formatNumber from "../../functions/common-functions/formatNumber";
import mapSnapshot from "../../functions/common-functions/mapSnapshot";
import loadUsersRT from "../../functions/loadUsersRT";
import { arrayIsEmpty } from "../../utils-functions/arrayIsEmpty";
import convertDate from "../../utils-functions/convertDate";
import MemberDetails from "./MemberDetails";

export default function UsersPage() {
    const [users, setUsers] = useState<UserType[]>([]);
    const [search, setSearch] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const { user } = useContext(AuthContext);
    const [page, setPage] = useState<number>(1);

    const fieldToDisplay = ["date", "displayName", "email", "phone", "addOn"];

    useEffect(() => {
        if (!user) return;
        if (user.role === "Super Admin") {
            const unsubscribe = loadUsersRT(user, setUsers, setLoading);
            return unsubscribe;
        } else {
            const collectionRef = collection(db, "users");
            const q = query(collectionRef, where("referral", "==", user.id));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const users = mapSnapshot(querySnapshot);
                setUsers(users);
                return unsubscribe;
            });
        }
    }, [user]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [page]);

    const handleSearch = (value: string) => {
        setPage(1);
        setSearch(value);
    };

    const checkUser = (user: UserType) => {
        console.log(user);
    };

    let filteredUsers = users?.map((user: UserType) => ({
        ...user,
        date: convertDate(user.date) || new Date("2020-01-01"),
    }));

    let totalPage = 1;

    if (!arrayIsEmpty(filteredUsers)) {
        filteredUsers = filteredUsers.filter((user: UserType) => {
            const searchField =
                convertToLowerCase(user.displayName) +
                convertToLowerCase(user.email) +
                convertToLowerCase(user.addOn || "");
            return searchField.includes(convertToLowerCase(search));
        });
        totalPage = Math.ceil(filteredUsers.length / 50);
        filteredUsers.sort(
            (a: UserType, b: UserType) =>
                new Date(b.date as Date).getTime() - new Date(a.date as Date).getTime()
        );
    }

    return (
        <Box m={1}>
            <Container maxWidth="sm">
                <Box display="flex" flexDirection="column" gap="16px">
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">Users Page</Typography>
                        <ExportToExcel fieldToDisplay={fieldToDisplay} data={filteredUsers} />
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Pagination
                            page={page}
                            count={totalPage}
                            onChange={(_, value) => setPage(value)}
                            color="primary"
                        />
                        <Typography variant="body2">
                            Total records: {filteredUsers && formatNumber(filteredUsers.length, 0)}
                        </Typography>
                    </Box>
                    <Box>
                        <TextField
                            label="Search"
                            variant="outlined"
                            value={search}
                            onChange={(e) => handleSearch(e.target.value)}
                            fullWidth
                        />
                    </Box>
                    <Box display="flex" flexDirection="column" gap="16px">
                        {loading ? (
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            filteredUsers &&
                            filteredUsers
                                .slice((page - 1) * 50, page * 50)
                                .map((user: UserType, i: number) => (
                                    <MemberDetails key={i} checkUser={checkUser} user={user} />
                                ))
                        )}
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Pagination
                            page={page}
                            count={totalPage}
                            onChange={(_, value) => setPage(value)}
                            color="primary"
                        />
                        <Typography variant="body2">
                            Total records: {filteredUsers && formatNumber(filteredUsers.length, 0)}
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}
