import ClearIcon from "@mui/icons-material/Clear";
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
} from "@mui/material";
import { collection, doc, getDoc, onSnapshot, query, updateDoc, where } from "firebase/firestore";
import React, { useContext, useEffect } from "react";
import { toast } from "react-hot-toast";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import useChooseFriend from "../../hooks/useChooseFriend";
import { Name } from "../../themes/themes";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";
import mapSnapshot from "../../utils-functions/mapSnapshot";

export default function AccountsDialog({ open, setOpen, setClientId, setWhatsappUser }) {
    const { user } = useContext(AuthContext);
    const [masterAccounts, setMasterAccounts] = React.useState([user.id]);
    const [currentMasterAccount, setCurrentMasterAccount] = React.useState(user.id);
    const [giveAccessTo, setGiveAccessTo] = React.useState([]);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setGiveAccessTo(user.giveAccessTo || []);
    }, [user]);

    useEffect(() => {
        const collectionRef = collection(db, "users");
        const q = query(collectionRef, where("giveAccessTo", "array-contains", user.id));
        let unsub2;
        const unsub = onSnapshot(q, (querySnapshot) => {
            const users = mapSnapshot(querySnapshot);

            const q2 = query(collectionRef, where("superAccount", "==", user.id));
            unsub2 = onSnapshot(q2, (querySnapshot) => {
                const subUsers = mapSnapshot(querySnapshot);
                const subAccounts = subUsers.map((d) => d.id);
                const masterAccounts = users.map((d) => d.id);

                const accounts = [...subAccounts, ...masterAccounts];

                // Remove duplicates
                const uniqueAccounts = [...new Set(accounts)];
                setMasterAccounts(uniqueAccounts);
            });
        });

        return () => {
            unsub();
            unsub2?.();
        };
    }, [user]);

    const chooseFriends = useChooseFriend();

    const handleGiveAccess = async () => {
        const response = await chooseFriends();
        if (response) {
            const docRef = doc(db, "users", user.id);
            const existing = user.giveAccessTo || [];
            if (existing.length === 5) {
                toast.error("You can only give access to 5 users");
                return;
            }
            const f = existing.find((id) => id === response.id);
            if (!f) {
                const giveAccessTo = [...existing, response.id];
                await updateDoc(docRef, { giveAccessTo });
                toast.success(`${response.displayName} has been given access`);
            } else {
                toast.error("User already has access");
            }
        }
    };

    const handleDelete = async (id) => {
        const docRef = doc(db, "users", user.id);
        const existing = user.giveAccessTo || [];
        const giveAccessTo = existing.filter((i) => i !== id);
        await updateDoc(docRef, { giveAccessTo });
        toast.success("Access has been removed");
    };

    const handleChangeMasterAccount = async (e) => {
        setCurrentMasterAccount(e.target.value);

        const docRef = doc(db, "users", e.target.value);
        const snapshot = await getDoc(docRef);
        const targetUser = mapDocSnapshot(snapshot);
        const clientId = targetUser.clientId;
        setClientId(clientId);
        setWhatsappUser(targetUser);
        setOpen(false);
    };

    return (
        <>
            <Button variant="contained" size={"small"} onClick={() => setOpen(true)}>
                Accounts
            </Button>
            <Dialog
                onClose={handleClose}
                open={open}
                PaperProps={{
                    sx: {
                        width: {
                            xs: "95vw", // width for extra-small screens
                            sm: "400px", // width for medium screens and above
                        },
                    },
                }}
            >
                <DialogTitle>Accounts control</DialogTitle>
                <Box m={"16px"}>
                    <Box display="flex" justifyContent={"space-between"}>
                        <Name>Account</Name>
                        <Select
                            size="small"
                            value={currentMasterAccount}
                            onChange={handleChangeMasterAccount}
                        >
                            <MenuItem value={user.id}>{user.id}</MenuItem>
                            {masterAccounts.map((id) => (
                                <MenuItem key={id} value={id}>
                                    {id}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <Box mt="8px" mb="8px">
                        <Divider />
                    </Box>
                    {user.addOnItems?.includes("masterAccount") && (
                        <React.Fragment>
                            <Box display="flex" justifyContent={"space-between"}>
                                <Name>Sub Accounts:</Name>
                                <Button variant="contained" size="small" onClick={handleGiveAccess}>
                                    Give Access to
                                </Button>
                            </Box>
                            <Box>
                                <List sx={{ width: "100%", maxWidth: 360 }}>
                                    {giveAccessTo.map((value) => (
                                        <ListItem
                                            key={value}
                                            disableGutters
                                            secondaryAction={
                                                <IconButton onClick={() => handleDelete(value)}>
                                                    <ClearIcon />
                                                </IconButton>
                                            }
                                        >
                                            <ListItemText primary={value} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        </React.Fragment>
                    )}
                </Box>
            </Dialog>
        </>
    );
}
