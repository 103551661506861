import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
    Box,
    Button,
    Chip,
    Container,
    Divider,
    IconButton,
    InputAdornment,
    LinearProgress,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import { Timestamp } from "firebase-admin/firestore";
import {
    addDoc,
    collection,
    doc,
    getDocs,
    onSnapshot,
    orderBy,
    query,
    updateDoc,
    where,
} from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ICheckoutData, ICustomer, IPlan } from "../../../functions/src/apis/Stripe/stripeCreateCheckout";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";
import mapDocSnapshotTS from "../../utils-functions/mapDocSnapshotTS";
import mapSnapshotTS from "../../utils-functions/mapSnapshotTS";

type IReseller = {
    id?: string;
    date: Date;
    name: string;
    stripeKey: string;
    admins: string[];
    hostname: string;
    email?: string;
    plans?: IPlan[];
    subscriptionPriceId?: string;
};

type IItemPlan = {
    active: boolean;
    id: string; // priceId
    amount: number;
    currency: string;
};

type IItem = {
    plan: IItemPlan;
};

export type ISubscription = {
    created: Timestamp;
    id?: string;
    status: string;
    items: IItem[];
    quantity: number;
};

export default function ResellerPage() {
    const [resellerId, setResellerId] = useState<string | "">("");
    const [reseller, setReseller] = useState<IReseller | null>(null);
    const [state, setState] = useState<IReseller | null>(null);
    const [showPassword, setShowPassword] = useState(false);
    const [plans, setPlans] = useState<IPlan[]>([]);
    const [loading, setLoading] = useState(false);
    const [checkoutId, setCheckoutId] = useState<string>("");
    const [url, setUrl] = useState<string | null>(null);
    const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
    const [hasSubPriceId, setHasSubPriceId] = useState(false);

    const { user } = useContext(AuthContext);

    const navigate = useNavigate();

    useEffect(() => {
        const getData = async () => {
            if (user) {
                if (user.addOnItems?.includes("reseller")) {
                    if (user.resellerId) {
                        setResellerId(user.resellerId);
                    }
                } else {
                    navigate("/");
                }
            }
        };

        getData();
    }, [user]);

    useEffect(() => {
        let hasSubPriceId = false;
        if (reseller && reseller.subscriptionPriceId) {
            subscriptions.forEach((sub) => {
                sub.items.forEach((item) => {
                    if (item.plan.id === reseller.subscriptionPriceId && sub.status === "active") {
                        hasSubPriceId = true;
                    }
                });
            });
        }
        setHasSubPriceId(hasSubPriceId);
    }, [reseller, subscriptions]);

    useEffect(() => {
        if (resellerId) {
            const unsubscribe = onSnapshot(doc(db, "resellers", resellerId), (snap) => {
                const reseller = mapDocSnapshotTS<IReseller>(snap as any);
                setReseller(reseller);
                setState(reseller);

                if (reseller?.plans) {
                    setPlans(reseller.plans);
                }
            });

            return unsubscribe;
        }
    }, [resellerId]);

    useEffect(() => {
        if (checkoutId) {
            console.log("Useeffect checkoutId: ", checkoutId);
            const docRef = doc(db, "stripeCheckouts", checkoutId);
            return onSnapshot(docRef, (snap) => {
                const checkout = mapDocSnapshot(snap);
                if (checkout?.url) {
                    console.log("checkout.url: ", checkout.url);
                    setUrl(checkout.url);
                    setLoading(false);
                }

                if (checkout.error) {
                    toast.error(checkout.error);
                    setLoading(false);
                }

                console.log("checkout: ", checkout);
            });
        }
    }, [checkoutId]);

    useEffect(() => {
        if (user) {
            const collectionRef = collection(db, "customers");
            const q = query(collectionRef, where("email", "==", user.id));

            let unsubscribe1: any = () => {};
            let unsubscribe2: any = () => {};

            unsubscribe1 = onSnapshot(q, (snap) => {
                const customers = mapSnapshotTS<ICustomer>(snap);
                const customer = customers[0];

                if (customer.id) {
                    const subscriptionsRef = collection(db, "customers", customer.id, "subscriptions");
                    const q = query(subscriptionsRef, orderBy("created", "desc"));
                    unsubscribe2 = onSnapshot(q, (snap) => {
                        const subscriptions = mapSnapshotTS<ISubscription>(snap);
                        setSubscriptions(subscriptions);
                    });
                }
            });

            return () => {
                unsubscribe1();
                unsubscribe2();
            };
        }
    }, [user]);

    const handleInitialize = async () => {
        console.log("Initialize");
        try {
            const collectionRef = collection(db, "resellers");
            const response = await addDoc(collectionRef, { date: new Date(), admins: [user.id] });
            setResellerId(response.id);

            const userRef = doc(db, "users", user.id);
            await updateDoc(userRef, { resellerId: response.id });
            toast.success("Reseller initialized successfully. Id: " + response.id);
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
        setState({ ...state, [key]: e.target.value } as IReseller);
    };

    const handleSave = async (key: keyof IReseller) => {
        if (resellerId && state) {
            if (state[key] === reseller?.[key]) return;

            let value = (state[key] as string).trim();

            if (key === "hostname") {
                value = value.toLocaleLowerCase();
                const resellerRef = collection(db, "resellers");
                const q = query(resellerRef, where("hostname", "==", value));
                const snap = await getDocs(q);

                // Check if hostname already exists
                if (!snap.empty) {
                    toast.error("Hostname already exists.");
                    const existingHostname = reseller?.hostname || "";
                    setState({ ...state, hostname: existingHostname });
                    return;
                }
            }

            const resellerRef = doc(db, "resellers", resellerId);
            await updateDoc(resellerRef, { [key]: value });
            toast.success("Saved successfully.");
        }
    };

    const handleAddPlan = async () => {
        if (resellerId) {
            const plan: IPlan = {
                name: "Plan",
                price: "RM358",
                pricePerYear: "RM300",
                currency: "usd",
                description: "",
                features: [],
                priceId: "",
                priceIdYearly: "",
                active: true,
                isPopular: false,
                tag: "",
            };

            const resellerRef = doc(db, "resellers", resellerId);
            await updateDoc(resellerRef, { plans: [...plans, plan] });
            setPlans([...plans, plan]);
        }
    };

    const handleAddSubscription = async () => {
        const priceId = state?.subscriptionPriceId || "";

        if (!priceId) {
            toast.error("Price Id is required.");
            return;
        }

        const successURL = window.location.origin;
        const cancelURL = window.location.origin;

        console.log("checkout");
        const collectionRef = collection(db, "stripeCheckouts");

        // Change this to allow promotion codes
        const allow_promotion_codes = true;

        setLoading(true);

        const checkoutData: ICheckoutData = {
            priceId,
            successURL,
            trialDays: 0,
            cancelURL,
            customerId: "", // will find customer id from userId
            allow_promotion_codes,
            userId: user.id,
        };

        const response = await addDoc(collectionRef, checkoutData);

        const checkoutId = response.id;
        console.log("checkoutId: ", checkoutId);
        setCheckoutId(checkoutId);
    };

    return (
        <Container>
            <Box display="flex" flexDirection={"column"} gap={1}>
                <Box mt={1} display="flex" justifyContent={"space-between"} alignItems={"center"}>
                    <Typography variant="h4">Reseller Portal</Typography>
                    {!resellerId && (
                        <Button variant="contained" onClick={handleInitialize}>
                            Initialize
                        </Button>
                    )}
                </Box>
                <Box my={1}>
                    <Divider />
                </Box>
                {resellerId && (
                    <>
                        <Box display="flex" gap={1} alignItems={"center"}>
                            <Typography sx={{ width: "100px" }}>Name</Typography>
                            <TextField
                                disabled={!resellerId}
                                size="small"
                                fullWidth
                                onBlur={() => handleSave("name")}
                                onChange={(e: any) => handleChange(e, "name")}
                                value={state?.["name"] || ""}
                            />
                        </Box>
                        <Box display="flex" gap={1} alignItems={"center"}>
                            <Typography sx={{ width: "100px" }}>Host name</Typography>
                            <TextField
                                disabled={!resellerId || user.role !== "SuperAdmin"}
                                size="small"
                                fullWidth
                                placeholder="djcsystem.com"
                                onBlur={() => handleSave("hostname")}
                                onChange={(e: any) => handleChange(e, "hostname")}
                                value={state?.["hostname"] || ""}
                            />
                        </Box>
                        <Box display="flex" gap={1} alignItems={"center"}>
                            <Typography sx={{ width: "100px" }}>Email</Typography>
                            <TextField
                                disabled={!resellerId}
                                size="small"
                                fullWidth
                                onBlur={() => handleSave("email")}
                                onChange={(e: any) => handleChange(e, "email")}
                                value={state?.["email"] || ""}
                            />
                        </Box>
                        <Box display="flex" gap={1} alignItems={"center"}>
                            <Typography sx={{ width: "100px" }}>Stripe API</Typography>
                            <TextField
                                disabled={!resellerId || user.role !== "Super Admin"}
                                size="small"
                                fullWidth
                                onBlur={() => handleSave("stripeKey")}
                                type={showPassword ? "text" : "password"}
                                onChange={(e: any) => handleChange(e, "stripeKey")}
                                value={state?.["stripeKey"] || ""}
                                slotProps={{
                                    input: {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPassword((prev) => !prev)}
                                                    edge="end"
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    },
                                }}
                            />
                        </Box>
                        <Box mt={2}>
                            <Divider>Plans</Divider>
                        </Box>
                        <Box>
                            <Button variant="contained" onClick={handleAddPlan}>
                                Add Plan
                            </Button>
                        </Box>
                        {plans.map((plan, index) => (
                            <Box key={index}>
                                <Plan plan={plan} reseller={reseller} index={index} />
                            </Box>
                        ))}
                        <Box mt={3}>
                            <Divider>Subscription</Divider>
                        </Box>
                        <Box display="flex" gap={1} alignItems={"center"}>
                            <Button
                                variant="contained"
                                sx={{ width: "180px" }}
                                onClick={handleAddSubscription}
                                disabled={loading || hasSubPriceId}
                            >
                                Add Subscription
                            </Button>
                            <TextField
                                size="small"
                                fullWidth
                                disabled={user.role !== "SuperAdmin"}
                                placeholder="Subscription Price Id"
                                value={state?.["subscriptionPriceId"] || ""}
                                onChange={(e: any) => handleChange(e, "subscriptionPriceId")}
                                onBlur={() => handleSave("subscriptionPriceId")}
                            />
                        </Box>
                        <Box>{loading && <LinearProgress />}</Box>
                        {url && (
                            <Box>
                                <a href={url} target="_blank" rel="noopener noreferrer">
                                    <Button
                                        variant="contained"
                                        size="large"
                                        sx={{ width: "100%" }}
                                        disabled={loading}
                                        color="warning"
                                    >
                                        Start my Subscription
                                    </Button>
                                </a>
                            </Box>
                        )}
                        <Box>
                            {subscriptions.map((subscription, index) => (
                                <Paper key={index} sx={{ padding: "10px", marginTop: "10px" }}>
                                    <Box
                                        display={"flex"}
                                        alignItems={"center"}
                                        justifyContent={"space-between"}
                                    >
                                        <Typography>Subscription {subscription.id}</Typography>
                                    </Box>

                                    <Box display="flex" alignItems="center" gap={1}>
                                        <Typography>Status: </Typography>
                                        <Chip
                                            size="small"
                                            label={subscription.status}
                                            color={subscription.status === "active" ? "success" : "error"}
                                        />
                                    </Box>

                                    {subscription.items.map((item, index) => (
                                        <Box key={index}>
                                            <Typography>Plan: {item.plan.id}</Typography>
                                            <Box
                                                display={"flex"}
                                                alignItems={"center"}
                                                justifyContent={"flex-start"}
                                                gap={1}
                                            >
                                                <Typography>Quantity: {subscription.quantity}</Typography>
                                                {reseller?.subscriptionPriceId === item.plan.id && (
                                                    <Button variant="contained" size="small">
                                                        Add quantity
                                                    </Button>
                                                )}
                                            </Box>
                                            <Typography>Amount: {item.plan.amount / 100}</Typography>
                                            <Typography>Currency: {item.plan.currency}</Typography>
                                        </Box>
                                    ))}
                                </Paper>
                            ))}
                        </Box>
                    </>
                )}
            </Box>
        </Container>
    );
}

const Plan = ({ plan, reseller, index }: { plan: IPlan; reseller: IReseller | null; index: number }) => {
    const [state, setState] = useState<IPlan | null>(null);
    const [features, setFeatures] = useState<string>("");

    useEffect(() => {
        setState(plan);
        const features = plan.features.join(", ");
        setFeatures(features);
    }, [plan]);

    const width = "130px";

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
        setState({ ...state, [key]: e.target.value } as IPlan);
    };

    const handleSave = async (key: keyof IPlan) => {
        const newPlans = reseller?.plans || [];
        const plans = [...newPlans];

        if (reseller && state) {
            if (key === "features") {
                if (JSON.stringify(features.split(",")) === JSON.stringify(plan.features)) return;
            } else {
                if (state[key] === plan[key]) return;
            }
        }

        plans[index] = state as IPlan;

        if (key === "features") {
            const featuresArray = features.split(",").map((item) => item.trim());
            plans[index].features = [...featuresArray];
            console.log(plans[index]);
        }

        if (reseller && reseller.id) {
            const resellerRef = doc(db, "resellers", reseller.id);
            await updateDoc(resellerRef, { plans: [...plans] });
            toast.success("Saved successfully.");
        }
    };

    const confirmation = useConfirmation();

    const handleDeletePlan = async (index: number) => {
        const response = await confirmation("Delete plan?", "Press ok to delete the plan.");

        if (!response) return;

        if (reseller && reseller.id) {
            const newPlans = reseller.plans || [];
            const plans = [...newPlans];
            plans.splice(index, 1);

            const resellerRef = doc(db, "resellers", reseller.id);
            await updateDoc(resellerRef, { plans: [...plans] });
            toast.success("Plan deleted successfully.");
        }
    };

    return (
        <Paper sx={{ padding: "10px", marginTop: "10px" }}>
            <Box display="flex" gap={1} alignItems={"center"} justifyContent={"flex-start"}>
                <Typography sx={{ width }}>Name</Typography>
                <TextField
                    size="small"
                    fullWidth
                    value={state?.name || ""}
                    onChange={(e: any) => handleChange(e, "name")}
                    onBlur={() => handleSave("name")}
                />
            </Box>
            <Box display="flex" gap={1} alignItems={"center"} justifyContent={"flex-start"} mt={1}>
                <Typography sx={{ width }}>Description</Typography>
                <TextField
                    size="small"
                    fullWidth
                    rows={3}
                    multiline
                    value={state?.description || ""}
                    onChange={(e: any) => handleChange(e, "description")}
                    onBlur={() => handleSave("description")}
                />
            </Box>
            <Box display="flex" gap={1} alignItems={"center"} justifyContent={"flex-start"} mt={1}>
                <Typography sx={{ width }}>Price monthly</Typography>
                <TextField
                    size="small"
                    fullWidth
                    value={state?.price || ""}
                    placeholder="RM358"
                    onChange={(e: any) => handleChange(e, "price")}
                    onBlur={() => handleSave("price")}
                />
            </Box>
            <Box display="flex" gap={1} alignItems={"center"} justifyContent={"flex-start"} mt={1}>
                <Typography sx={{ width }}>Price Id monthly</Typography>
                <TextField
                    size="small"
                    fullWidth
                    value={state?.priceId || ""}
                    placeholder="Stripe price id"
                    onChange={(e: any) => handleChange(e, "priceId")}
                    onBlur={() => handleSave("priceId")}
                />
            </Box>
            <Box display="flex" gap={1} alignItems={"center"} justifyContent={"flex-start"} mt={1}>
                <Typography sx={{ width }}>Price yearly</Typography>
                <TextField
                    size="small"
                    fullWidth
                    placeholder="RM300"
                    value={state?.pricePerYear || ""}
                    onChange={(e: any) => handleChange(e, "pricePerYear")}
                    onBlur={() => handleSave("pricePerYear")}
                />
            </Box>
            <Box display="flex" gap={1} alignItems={"center"} justifyContent={"flex-start"} mt={1}>
                <Typography sx={{ width }}>Price Id yearly</Typography>
                <TextField
                    size="small"
                    fullWidth
                    value={state?.priceIdYearly || ""}
                    placeholder="Stripe price id"
                    onChange={(e: any) => handleChange(e, "priceIdYearly")}
                    onBlur={() => handleSave("priceIdYearly")}
                />
            </Box>
            <Box display="flex" gap={1} alignItems={"center"} justifyContent={"flex-start"} mt={1}>
                <Typography sx={{ width }}>Features</Typography>
                <TextField
                    size="small"
                    fullWidth
                    value={features || ""}
                    rows={4}
                    multiline
                    placeholder="Features separated by comma (,) e.g. feature1, feature2"
                    onChange={(e: any) => setFeatures(e.target.value)}
                    onBlur={() => handleSave("features")}
                />
            </Box>

            <Box display="flex" gap={1} alignItems={"center"} justifyContent={"flex-end"} mt={1}>
                <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={() => handleDeletePlan(index)}
                >
                    Delete
                </Button>
            </Box>
        </Paper>
    );
};
