import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Button, Tooltip, Typography, Box, Divider, Grid, Container } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logEvent, setUserProperties } from "firebase/analytics";
import { AuthContext } from "../../context/AuthContext";
import { analytics } from "../../firebase/firebase-utils";
import useVersionCheck from "../../hooks/useVersionCheck";
import { HOMEPAGE_ITEMS } from "../../settings/settings";

const STAGE = process.env.REACT_APP_STAGE;

export default function ExplosoftaiPage() {
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        logEvent(analytics, "user_checkin", {
            user_id: user.id,
            user_name: user.displayName,
            user_email: user.email,
            timestamp: new Date().toISOString(),
        });

        setUserProperties(analytics, {
            user_id: user.id,
            user_email: user.email,
            user_name: user.displayName,
            last_login: new Date().toISOString(),
        });
    }, [user]);

    const enabledItem = (item: any) => {
        if (item.addOn === "whatsappCloud" && user.addOn !== "whatsappCloud") return false;
        return true;
    };

    useVersionCheck();

    const displayOrNot = (item: any) => {
        let display = true;

        if (user.role === "Super Admin") return true;

        if (item.role) {
            if (user.role !== item.role) display = false;
        }
        if (item.hide) {
            if (item.hide === user.role) display = false;
        }

        return display;
    };

    return (
        <Box m={2}>
            <Box display="flex" flexDirection="column" alignItems="center">
                {STAGE === "stage" && (
                    <Typography variant="body1" color="textSecondary">
                        Staging Account
                    </Typography>
                )}
                <Typography variant="h5" mt={2} mb={2}>
                    {user ? `Welcome ${user.displayName}` : "Homepage"}
                </Typography>
                <Divider sx={{ width: "100%", marginBottom: 2 }} />
                <Container disableGutters>
                    <Grid container spacing={1} justifyContent="flex-start">
                        {HOMEPAGE_ITEMS.map((item: any, i) => {
                            if (displayOrNot(item)) {
                                if (
                                    item.description === "title" ||
                                    item.description === "divider" ||
                                    !item.imageUrl
                                ) {
                                    return <React.Fragment key={i}></React.Fragment>;
                                }

                                return (
                                    <Grid item xs={6} md={3} key={i} display="flex">
                                        <Box
                                            onClick={() => navigate(item.link)}
                                            sx={{
                                                cursor: "pointer",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                textAlign: "center",
                                            }}
                                        >
                                            <Box
                                                component="img"
                                                src={`/Explosoftai/${item.imageUrl}`}
                                                alt={item.title}
                                                sx={{
                                                    width: ["45vw", "280px"],
                                                    borderRadius: 1,
                                                    marginBottom: 1,
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                );
                            }
                            return null;
                        })}
                    </Grid>
                </Container>
            </Box>
        </Box>
    );
}
