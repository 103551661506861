import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";

export default function useSubscriber() {
    const [isSubscriber, setIsSubscriber] = useState(false);
    const { user } = useContext(AuthContext);

    useEffect(() => {
        if (user && user.addOn === "whatsappCloud") {
            setIsSubscriber(true);
        } else {
            setIsSubscriber(false);
        }
    }, [user]);

    return { isSubscriber };
}
