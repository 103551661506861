import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { collection, doc, getCountFromServer, getDocs, query, setDoc, where } from "firebase/firestore";
import { auth, db } from "../firebase/firebase-utils";
import { notification } from "../utils-functions/notification";
import removeSpecialChars from "./common-functions/removeSpecialChars";
import formatPhoneNumber from "./common-functions/formatPhoneNumber";
import isIQI from "./isIQI";

export default async function signUp(fullName, email, phone, password, setUser, setAuthUser, signupReferral) {
    try {
        phone = formatPhoneNumber(phone);
        email = email.toLowerCase().trim();

        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const displayName = capitalizeFirstLetter(fullName);

        await updateProfile(auth.currentUser, { displayName: displayName });

        console.log("Updated displayName");

        const authUser = userCredential.user;

        console.log(authUser);

        const user = await createNewUser(displayName, email, phone, setUser, authUser, signupReferral);

        setAuthUser(authUser);

        console.log(user, " is created");

        return true;
    } catch (err) {
        console.log(err);
        let message = err.message;

        if (err.code === "auth/email-already-in-use") {
            message = "This email is already in use. Please use another email";
        }
        notification("Error", message, "warning");
        return false;
    }
}

const createNewUser = async (displayName, email, phone, setUser, authUser, signupReferral) => {
    //
    let userId = 0;

    try {
        const collectionRef = collection(db, "users");
        const snapshot = await getCountFromServer(collectionRef);
        const count = snapshot.data().count;
        userId = count + 1;
    } catch (err) {
        console.log(err);
    }

    try {
        //
        const userRef = doc(db, "users", email);

        let clientId = removeSpecialChars(email);

        if (isIQI()) {
            clientId = removeSpecialChars(email) + "_iqi";
        }

        // check if clientId is already taken
        const collectionRef = collection(db, "users");
        const q = query(collectionRef, where("clientId", "==", clientId));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            clientId = userId + clientId;
        }

        const userData = {
            date: new Date(),
            email,
            phone,
            displayName,
            id: email,
            uid: authUser.uid,
            clientId,
            referral: signupReferral.fullId,
            referralId: signupReferral.shortId,
            userId,
        };

        console.log(userRef);

        await setDoc(userRef, userData, { merge: true });

        setUser(userData);

        console.log("Firebase user created", displayName);

        return userData;
        //
    } catch (err) {
        console.log(err);
    }
};

function capitalizeFirstLetter(string) {
    return string
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(" ")
        .trim();
}
