import { collection, doc, getDoc, getDocs, query, updateDoc, where } from "@firebase/firestore";
import { Button, Grid, IconButton, Alert as MuiAlert, Snackbar, TextField, Typography } from "@mui/material";
import { forwardRef, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";

//Icons
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import useWindowSize from "../../hooks/useWindowSize";
import useSubscriber from "../../hooks/useSubscriber";
import toast from "react-hot-toast";

//Snackbar
const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ReferralCodePanel = () => {
    const { user } = useContext(AuthContext);
    const [referralCode, setReferralCode] = useState("");
    const [affiliateLink, setAffiliateLink] = useState();
    const [error, setError] = useState();
    const [open, setOpen] = useState(false);
    const [focus, setFocus] = useState(false);
    const [alertContent, setAlertContent] = useState({
        content: "",
        severity: "success",
    });
    const windowSize = useWindowSize();

    const { isSubscriber } = useSubscriber();

    //close handler for the snackbar
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    // To generate the affiliate link, by dynamically getting the current protocol and hostname
    const generateAffiliateLink = (rCode) => {
        const currentProtocol = window.location.protocol;
        const currentDomain = window.location.hostname;
        const protocolAndDomain = currentProtocol + "//" + currentDomain;

        if (process.env.REACT_APP_STAGE == "production") {
            setAffiliateLink(protocolAndDomain + `/sign-up/${rCode}`);
        } else {
            setAffiliateLink(protocolAndDomain + `:3000/sign-up/${rCode}`);
        }
    };

    //handle referral code change in text field
    const handleReferralCodeChange = (event) => {
        setError({});
        setReferralCode(event.target.value);
    };

    //To validate the referral code inputed by the user
    const validateReferralCode = (rCode) => {
        if (typeof rCode !== "string") {
            setError({
                isTrue: true,
                message: "Invalid code.",
            });
            return false; // Not a string
        }

        if (rCode.trim().length > 15 || rCode.trim().length == 0) {
            setError({
                isTrue: true,
                message: "The referral code need to be 15 characters or less.",
            });
            return false; // Length is greater than 15 and more than 0
        }

        // Check if the string contains only alphanumeric characters
        if (!/^[a-zA-Z0-9]*$/.test(rCode)) {
            setError({
                isTrue: true,
                message: "The referral code only accept alphanumeric characters.",
            });
            return false;
        }

        return true; // String is valid
    };

    //To handle affiliate link copy to clipboard
    const handleCopy = () => {
        navigator.clipboard.writeText(affiliateLink);
        setAlertContent({
            content: "Copied to clipboard.",
            severity: "success",
        });
        setOpen(true);
    };

    //Main function: to handle referral code generation
    const handleReferralCodeSubmit = async (event) => {
        event.preventDefault();

        if (!isSubscriber) {
            toast.error("You need to be a subscriber to generate an affiliate link.");
            return;
        }
        console.log("referralCode: ", referralCode);

        //If the referral code is invalid, quit.
        if (!validateReferralCode(referralCode)) return;

        const usersCol = collection(db, "users");
        const q = query(usersCol, where("referralCode", "==", referralCode));

        try {
            const snapShot = await getDocs(q);
            if (snapShot.empty) {
                //Affiliate code is unique, can proceed.
                const userRef = doc(db, "users", user.id);
                await updateDoc(userRef, {
                    referralCode: referralCode,
                });
                console.log("Done updating the referralCode");

                generateAffiliateLink(referralCode);

                setAlertContent({
                    content: "Referral code created.",
                    severity: "success",
                });
                setOpen(true);
            } else {
                //Affiliate code already exist, throw error message.
                setError({
                    isTrue: true,
                    message: "This referral code already exist, try another one.",
                });
            }
        } catch (err) {
            console.log(err.message);
        }
    };

    //When first load, fetch referral code to generate affiliate link if exist.
    useEffect(() => {
        const fetchReferralCode = async () => {
            const userRef = doc(db, "users", user.id);
            const userSnapshot = await getDoc(userRef);

            if (userSnapshot.exists()) {
                console.log("userSnapshot: ", userSnapshot.data());
                let rCode = userSnapshot.data().referralCode;
                if (rCode) {
                    setReferralCode(rCode);
                    generateAffiliateLink(rCode);
                    setFocus(true);
                } else {
                    setReferralCode("");
                }
            }
        };

        fetchReferralCode();
    }, []);

    return (
        <Grid container sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={alertContent.severity} sx={{ width: "100%" }}>
                    {alertContent.content}
                </Alert>
            </Snackbar>

            {affiliateLink && (
                <Grid
                    item
                    justifyContent="center"
                    mb="1%"
                    alignItems="center"
                    xs={12}
                    sx={{ display: "flex", flexDirection: "column" }}
                >
                    <Typography variant="h5">Your affiliate link:</Typography>
                    <Grid
                        item
                        justifyContent="center"
                        alignItems="center"
                        sx={{ display: "flex", width: "100%" }}
                    >
                        <Typography
                            variant={windowSize.width > 600 ? "h6" : "subtitle2"}
                            component="span"
                            color="primary"
                            px="1%"
                        >
                            {affiliateLink}
                        </Typography>
                        <IconButton
                            color="primary"
                            aria-label="copy to clipboard"
                            sx={{ display: "inline-block" }}
                            onClick={handleCopy}
                        >
                            <ContentCopyIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            )}
            <Grid
                item
                xs={12}
                component="form"
                noValidate
                alignItems="center"
                justifyContent="center"
                onSubmit={handleReferralCodeSubmit}
                sx={{ width: "100%", display: "flex" }}
            >
                <TextField
                    name="rCode"
                    label="Referral Code"
                    variant="outlined"
                    value={referralCode ? referralCode : ""}
                    autoFocus={focus}
                    error={error ? error.isTrue : false}
                    helperText={error ? error.message : null}
                    onChange={handleReferralCodeChange}
                />
                <Button variant="text" type="submit" size="small">
                    Generate Code
                </Button>
            </Grid>
        </Grid>
    );
};

export default ReferralCodePanel;
