import AttachFileIcon from "@mui/icons-material/AttachFile";
import CancelIcon from "@mui/icons-material/Cancel";
import SendIcon from "@mui/icons-material/Send";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Icon,
    IconButton,
    InputLabel,
    Link,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import {
    addDoc,
    and,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    onSnapshot,
    or,
    orderBy,
    query,
    updateDoc,
    where,
} from "firebase/firestore";
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";
import React, { useContext, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useInput } from "../../context-utils/InputContext";
import { AuthContext } from "../../context/AuthContext";
import { db, storage } from "../../firebase/firebase-utils";
import useColors from "../../hooks/useColors";
import useWindowSize from "../../hooks/useWindowSize";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";
import mapSnapshotTS from "../../utils-functions/mapSnapshotTS";
import AddCase from "./AddCase";
import { CaseItemComponent } from "./CaseItemComponent";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useLocation, useNavigate } from "react-router-dom";
import WhatsappConnectionComponent from "../ChatInboxPage/WhatsappConnectionComponent";

export type User = {
    id: string;
    displayName: string;
    companyIds?: string[];
    clientId: string;
};

export type Company = {
    id: string;
    name: string;
    admins: string[];
};

// Define the Comment interface with expected fields
export type Comment = {
    id: string; // Firestore document ID
    text: string; // The comment text
    createdAt: Date; // The timestamp when the comment was created
    createdBy: string;
    createdByName: string;
    files?: { name: string; downloadURL: string }[];
    // Add any other fields you expect in a comment
};

export type Case = {
    id: string;
    date: Date;
    prospectName: string;
    prospectPhone: string;
    prospectEmail: string;
    productId: string;
    value: string;
    agentIds: string[];
    agentNames: string[];
    editorIds?: string[];
    editorNames?: string[];
    percentages: string[];
    customValues: string[];
    customRemarks: string[];
    remark: string;
    companyId: string;
    createdBy: string;
    created: Date;
    profilePicUrl?: string;
    admins: string[];
    viewerIds: string[]; // Optional viewerIds field
    viewerNames: string[]; // Optional viewerNames field
    caseId?: string[];
    latestCompanyId: string;

    customFields?: ICustomField[];
} | null;

export type Selection = "VIEW_CASES";

export type ICustomField = {
    label: string;
    value: string;
    type: string | number;
};

interface LocationState {
    companyId?: string;
}

export default function CasesPage(): React.JSX.Element {
    const [currentCompanyId, setCurrentCompanyId] = useState<string>("");
    const [companies, setCompanies] = useState<Company[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [columnWidths, setColumnWidths] = useState<number[]>([30, 40, 30]);
    const [columnDisplay, setColumnDisplay] = useState<number[]>([1, 1, 1]);
    const [currentColumn, setCurrentColumn] = useState<number>(0);
    const [currentSelection, setCurrentSelection] = useState<Selection>("VIEW_CASES");

    const [currentCaseId, setCurrentCaseId] = useState<string>("");
    const [currentCase, setCurrentCase] = useState<Case>(null);

    const [cases, setCases] = useState<Case[]>([]);

    const { user } = useContext(AuthContext) as { user: User };
    const input = useInput();
    const colors = useColors();
    const inputRef = React.useRef<HTMLInputElement | null>(null);

    const [inputText, setInputText] = useState<string>("");
    const [caseComments, setCaseComments] = useState<Comment[]>([]);

    const [attachment, setAttachment] = useState<File | null>(null);
    const [uploadFiles, setUploadFiles] = useState<File[]>([]);

    const [deleteItemId, setDeleteItemId] = useState<string | null>(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

    const [editItem, setEditItem] = useState<any>(null);
    const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);

    const [newComment, setNewComment] = useState<string>("");

    const [deleteFileDialogOpen, setDeleteFileDialogOpen] = useState(false);
    const [fileToDelete, setFileToDelete] = useState<{ file: any; index: number; commentId: string } | null>(
        null
    );

    const [deleteCompanyDialogOpen, setDeleteCompanyDialogOpen] = useState<boolean>(false);
    const [deleteCompanyId, setDeleteCompanyId] = useState<string | null>(null);
    const [deleteCompanyData, setDeleteCompanyData] = useState<any>(null);

    const [refreshKey, setRefreshKey] = useState(0); // State to trigger refresh

    const [whatsappUser, setWhatsappUser] = useState<User | null>(null);
    const [whatsappSession, setWhatsappSession] = useState<string>("");
    const [apiAddress, setApiAddress] = useState<string>("");
    const [whatsappPhone, setWhatsappPhone] = useState<string>("");
    const [secret, setSecret] = useState<string>("");
    const [clientId, setClientId] = useState<string>("");

    const windowSize = useWindowSize();

    const navigate = useNavigate();
    const location = useLocation();

    const { companyId } = (location.state as LocationState) || {};

    const isMobile = useMemo(() => {
        if (windowSize.width) {
            return windowSize.width < 600;
        } else {
            return false;
        }
    }, [windowSize.width]);

    // // Update currentCompanyId if there's a value in location.state
    // useEffect(() => {
    //     if (location.state?.currentCompanyId) {
    //         setCurrentCompanyId(location.state.currentCompanyId);
    //         console.log("current CompanyId is", currentCompanyId)
    //     }
    // }, [location.state]);

    useEffect(() => {
        if (!user) return;
        const clientId = user.clientId;
        setClientId(clientId);
        setWhatsappUser(user);
    }, [user]);

    useEffect(() => {
        if (isMobile) {
            switch (currentColumn) {
                case 0: {
                    setColumnDisplay([1, 0, 0]);
                    setColumnWidths([100, 0, 0]);
                    break;
                }
                case 1: {
                    setColumnDisplay([0, 1, 0]);
                    setColumnWidths([0, 100, 0]);
                    break;
                }
                case 2: {
                    setColumnDisplay([0, 0, 1]);
                    setColumnWidths([0, 0, 100]);
                    break;
                }
                default: {
                    setColumnDisplay([1, 0, 0]);
                    setColumnWidths([100, 0, 0]);
                    break;
                }
            }
        } else {
            setColumnDisplay([1, 1, 1]);
            setColumnWidths([30, 40, 30]);
        }
    }, [isMobile, currentColumn]);

    useEffect(() => {
        // Set the current company ID when the component mounts
        // and we have a company ID in the navigation state
        if (companyId) {
            setCurrentCompanyId(companyId);
        }
    }, [companyId]);

    useEffect(() => {
        setCurrentCaseId("");
    }, [currentCompanyId]);

    useEffect(() => {
        if (!user) return;

        const collectionRef = collection(db, "companies");
        const q = query(collectionRef, where("admins", "array-contains", user.id));

        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
            let companies = mapSnapshotTS<Company>(querySnapshot);

            const userCompanyIds = user.companyIds || [];

            const userCompanies = await Promise.all(
                userCompanyIds.map(async (companyId) => {
                    const docRef = doc(db, "companies", companyId);
                    const snapshot = await getDoc(docRef);
                    const company = mapDocSnapshot(snapshot);
                    return company;
                })
            );

            companies = [...companies, ...userCompanies];

            // Remove any null or undefined values
            companies = companies.filter(Boolean);

            // Remove duplicates based on id
            companies = companies.filter(
                (company, index, self) => index === self.findIndex((t) => t.id === company.id)
            );

            // order by name
            companies = companies.sort((a, b) => a.name.localeCompare(b.name));

            setCompanies(companies);
        });

        return unsubscribe;
    }, [user]);

    useEffect(() => {
        if (!currentCaseId) {
            setCurrentCase(null);

            return;
        }

        const docRef = doc(db, "cases", currentCaseId);
        const unsubscribe = onSnapshot(docRef, (doc: any) => {
            const caseData = mapDocSnapshot(doc);

            setCurrentCase(caseData);
            console.log("Case Data ", caseData);
        });

        return unsubscribe;
    }, [currentCaseId]);

    useEffect(() => {
        if (!user || !currentCompanyId) return;

        const companyRef = doc(db, "companies", currentCompanyId);

        // First, check if the current user is an admin in the company
        onSnapshot(companyRef, (doc) => {
            if (!doc.exists()) {
                console.log("No company found");
                return;
            }

            const companyData = doc.data();
            const isCompanyAdmin = companyData?.admins?.includes(user.id);

            // Define the cases query based on whether the user is a company admin
            const collectionRef = collection(db, "cases");
            const q = isCompanyAdmin
                ? query(collectionRef, where("companyId", "==", currentCompanyId), orderBy("date", "desc"))
                : query(
                      collectionRef,
                      and(
                          where("companyId", "==", currentCompanyId),
                          or(
                              where("admins", "array-contains", user.id),
                              where("viewerIds", "array-contains", user.id),
                              where("editorIds", "array-contains", user.id)
                          )
                      ),
                      orderBy("date", "desc")
                  );

            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const cases = mapSnapshotTS<Case>(querySnapshot);
                setCases(cases);
                console.log(cases);
            });

            return () => unsubscribe();
        });
    }, [user, currentCompanyId]);

    const handleChangeCompany = async (e: any) => {
        if (e.target.value === "addCompany") {
            console.log(e.target.value);
            const response = await input("Add new company", "Enter the company name", "Company name", "");
            const trimResponse = response.trim();

            if (!trimResponse) {
                toast.error("Please enter a company name");
                return;
            }

            if (trimResponse) {
                // add new company
                const collectionRef = collection(db, "companies");

                // Get all companies and check case-insensitive match
                const querySnapshot = await getDocs(collectionRef);
                const exists = querySnapshot.docs.some(
                    (doc) => doc.data().name.toLowerCase() === trimResponse.toLowerCase()
                );

                // If company already exists (case-insensitive), show error toast
                if (exists) {
                    toast.error("A company with this name already exists!");
                    return;
                }

                // If no duplicate found, proceed with adding new company
                const { id } = await addDoc(collectionRef, {
                    name: trimResponse,
                    admins: [user.id],
                    created: new Date(),
                    createdBy: user.id,
                });
                setCurrentCompanyId(id);
                toast.success("Company created successfully!");
            }
        } else {
            setCurrentCompanyId(e.target.value as string);
        }
    };

    const handleChangeSelection = (e: any) => {
        setCurrentSelection(e.target.value);
        setCurrentColumn(1);
    };

    const getComponent = () => {
        switch (currentSelection) {
            case "VIEW_CASES": {
                return (
                    <AddCase
                        companyId={currentCompanyId}
                        currentCase={currentCase}
                        setCurrentCaseId={setCurrentCaseId}
                        secret={secret}
                        apiAddress={apiAddress}
                        whatsappSession={whatsappSession}
                    />
                );
            }
            default: {
                return (
                    <AddCase
                        companyId={currentCompanyId}
                        currentCase={currentCase}
                        setCurrentCaseId={setCurrentCaseId}
                        secret={secret}
                        apiAddress={apiAddress}
                        whatsappSession={whatsappSession}
                    />
                );
            }
        }
    };

    const handleInputChange = (e: any) => {
        setInputText(e.target.value);
    };

    const handleAddCaseComment = async () => {
        if (!currentCaseId) return;

        try {
            // Step 1: Upload files and get their URLs
            const fileData = await Promise.all(
                uploadFiles.map(async (file) => {
                    const storageRef = ref(storage, `caseComments/${currentCaseId}/${file.name}`);
                    await uploadBytes(storageRef, file);
                    const downloadURL = await getDownloadURL(storageRef);
                    return {
                        name: file.name,
                        downloadURL: downloadURL,
                        path: storageRef.fullPath, // Get the full path of the uploaded file
                        type: file.type, // Get the file type
                    }; // Store both file name and URL
                })
            );

            // Step 2: Save comment and file URLs into Firestore
            const commentData = {
                text: inputText, // The comment text
                files: fileData, // The array of file URLs
                createdAt: new Date(),
                createdBy: user.id, // Assuming you want to save the user ID who created the comment
                createdByName: user.displayName, // Assuming you want to save the user name who created the comment
            };

            const caseRef = doc(db, "cases", currentCaseId);
            await addDoc(collection(caseRef, "comments"), commentData);

            // Step 3: Clear the input fields and uploaded files
            setInputText(""); // Clear the comment input
            setUploadFiles([]); // Clear uploaded files
        } catch (error) {
            console.error("Error adding comment:", error);
        }
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(event.target.files || []); // Get selected files
        setUploadFiles((prevFiles: File[]) => [...prevFiles, ...files]); // Append new files to existing ones
    };

    const removeUploadFile = (index: number) => {
        setUploadFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    useEffect(() => {
        if (!currentCaseId) return; // Prevent running if there's no case selected

        const collectionRef = collection(db, "cases", currentCaseId, "comments");
        const q = query(collectionRef, orderBy("createdAt", "desc")); // Ensure this field exists

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const comments: Comment[] = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...(doc.data() as Omit<Comment, "id">), // Type assertion for remaining fields
                createdAt: doc.data().createdAt ? new Date(doc.data().createdAt.seconds * 1000) : new Date(), // Handle Firestore timestamps
            }));

            setCaseComments(comments); // Set comments array in state
            console.log(comments);
        });

        return unsubscribe; // Cleanup subscription on unmount
    }, [currentCaseId]); // Add currentCaseId as a dependency

    const handleEditComment = (comment: { id: string; text: string }) => {
        // Handle edit comment logic here
        setEditItem(comment);
        setNewComment(comment.text);

        setEditDialogOpen(true);
        console.log("Edit comment:", comment);
    };

    const handleConfirmEdit = async () => {
        if (editItem) {
            try {
                const docRef = doc(db, "cases", currentCaseId, "comments", editItem.id);
                await updateDoc(docRef, {
                    text: newComment,
                });
                setEditDialogOpen(false);
            } catch (error) {
                console.error("Error updating document: ", error);
            }
        }
    };

    const handleCancelEdit = () => {
        setEditItem(null);
        setEditDialogOpen(false);
    };

    const handleDeleteComment = (commentId: string) => {
        // Handle delete comment logic here
        setDeleteItemId(commentId);

        setDeleteDialogOpen(true);
        console.log("Delete comment:", commentId);
    };

    const handleConfirmDelete = async () => {
        if (deleteItemId) {
            try {
                const docRef = doc(db, "cases", currentCaseId, "comments", deleteItemId);

                // Step 1: Get the comment document to retrieve file paths
                const commentDoc = await getDoc(docRef);
                if (commentDoc.exists()) {
                    const commentData = commentDoc.data();

                    // Step 2: Delete files from Firebase Storage
                    if (commentData.files && Array.isArray(commentData.files)) {
                        await Promise.all(
                            commentData.files.map(async (file) => {
                                console.log("File path:", file.path); // Log the file path to check it
                                const fileRef = ref(storage, file.path); // Use the stored file path
                                await deleteObject(fileRef); // Delete the file from Firebase Storage
                            })
                        );
                    }

                    // Step 3: Delete the comment document from Firestore
                    await deleteDoc(docRef);

                    // Step 4: Close the dialog and reset the state
                    setDeleteDialogOpen(false);
                    setDeleteItemId(null); // Reset after deletion
                } else {
                    console.error("Document does not exist.");
                }
            } catch (error) {
                console.error("Error deleting document and files: ", error);
            }
        }
    };

    const handleCancelDelete = () => {
        setDeleteItemId(null);
        setDeleteDialogOpen(false);
    };

    const deleteUploadFile = (file: any, index: number, commentId: string) => {
        setFileToDelete({ file, index, commentId });
        setDeleteFileDialogOpen(true);
    };

    const handleConfirmDeleteFile = async () => {
        console.log("Confirm delete file clicked.");
        if (fileToDelete) {
            const { file, index, commentId } = fileToDelete;
            try {
                const fileRef = ref(storage, file.path);
                await deleteObject(fileRef);

                const commentRef = doc(db, "cases", currentCaseId, "comments", commentId);
                const commentDoc = await getDoc(commentRef);

                if (commentDoc.exists()) {
                    const commentData = commentDoc.data();
                    const updatedFiles = commentData.files.filter(
                        (_: any, fileIndex: number) => fileIndex !== index
                    );
                    await updateDoc(commentRef, { files: updatedFiles });
                    console.log("File deleted and Firestore updated.");
                }
            } catch (error) {
                console.error("Error deleting file: ", error);
            } finally {
                setDeleteFileDialogOpen(false);
                setFileToDelete(null);
            }
        } else {
            console.log("No file to delete.");
        }
    };

    const handleCancelDeleteFile = () => {
        setFileToDelete(null);
        setDeleteFileDialogOpen(false);
    };

    useEffect(() => {
        if (deleteCompanyId) {
            const docRef = doc(db, "companies", deleteCompanyId);
            const unsubscribe = onSnapshot(docRef, (doc) => {
                const companyData = mapDocSnapshot(doc);
                console.log(companyData);
                setDeleteCompanyData(companyData);
            });
            return unsubscribe;
        }
    }, [deleteCompanyId]);

    const handleConfirmDeleteCompany = async (): Promise<void> => {
        // Early validation checks
        if (!deleteCompanyId) {
            toast.error("No company selected for deletion");
            setDeleteCompanyDialogOpen(false);
            return;
        }

        try {
            // First check if user is company admin
            const companyRef = doc(db, "companies", deleteCompanyId);
            const companySnap = await getDoc(companyRef);

            if (!companySnap.exists()) {
                toast.error("Company not found");
                return;
            }

            const companyData = companySnap.data();
            const isCompanyAdmin = companyData.admins?.includes(user.id);

            if (!isCompanyAdmin) {
                setDeleteCompanyDialogOpen(false);
                toast.error("Only company administrators can delete company");

                return;
            }

            // Check for existing cases
            const casesRef = collection(db, "cases");
            const q = query(casesRef, where("companyId", "==", deleteCompanyId));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                toast.error("Cannot delete company. Cases exist under this company.");
                setDeleteCompanyDialogOpen(false);
                return;
            }

            // Proceed with deletion if all checks pass
            await deleteDoc(doc(db, "companies", deleteCompanyId));
            toast.success("Company deleted successfully");

            // Clean up and refresh
            setRefreshKey((prev) => prev + 1);
            setDeleteCompanyId(null);
            setDeleteCompanyDialogOpen(false);
        } catch (error) {
            console.error("Error deleting company:", error);
            toast.error("Failed to delete company");
            setDeleteCompanyDialogOpen(false);
        }
    };

    //   // Example delete function with specified parameter type
    //   const deleteCompany = (companyId: string) => {
    //       // Call your delete API or Firestore function here
    //       console.log(`Deleting company with ID: ${companyId}`);
    //   };

    const handleDeleteCompany = (companyId: string) => {
        // Handle delete company logic here
        setDeleteCompanyId(companyId);

        setDeleteCompanyDialogOpen(true);
        console.log("Delete company:", companyId);
    };

    return (
        <Box display="flex" width={"100vw"} height={`calc(100vh - 55px)`}>
            {/* First column */}
            <Box
                display={columnDisplay[0] ? "flex" : "none"}
                flexDirection={"column"}
                width={columnWidths[0] + "%"}
                height={"100%"}
                sx={{ borderRight: "1px solid grey" }}
            >
                <Box
                    p={1}
                    py={2}
                    width={"100%"}
                    height={"86px"}
                    sx={{ borderBottom: "1px solid grey" }}
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <FormControl fullWidth>
                        <InputLabel>Company</InputLabel>
                        <Select value={currentCompanyId} label="Company" onChange={handleChangeCompany}>
                            {companies.map((company) => (
                                <MenuItem key={company.id} value={company.id}>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        width="100%"
                                    >
                                        <Typography>{company.name}</Typography>

                                        {company.admins.includes(user.id) && ( // Check if user is an admin
                                            <IconButton
                                                edge="end"
                                                size="small"
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevents select dropdown from closing
                                                    handleDeleteCompany(company.id); // Pass the specific company ID
                                                }}
                                            >
                                                <Icon sx={{ color: "red" }}>delete</Icon>
                                            </IconButton>
                                        )}
                                    </Box>
                                </MenuItem>
                            ))}
                            <Divider />
                            <MenuItem value={"addCompany"}>
                                <Icon sx={{ marginRight: "8px" }}>add</Icon>
                                Add New Company
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <Box display="flex" alignItems={"center"} gap={1}>
                        {isMobile && (
                            <IconButton onClick={() => setCurrentColumn(1)}>
                                <Icon>settings</Icon>
                            </IconButton>
                        )}
                    </Box>
                    <Box mx={1}>
                        <Button
                            variant="contained"
                            onClick={() => navigate(`/cases-report?companyId=${currentCompanyId}`)}
                            disabled={!currentCompanyId} // Optional: Disable if no company is selected
                        >
                            Case Report
                        </Button>
                    </Box>
                </Box>
                <Box display="flex" flexDirection={"row"} p={1} alignItems={"center"}>
                    <Select
                        value={currentSelection}
                        onChange={handleChangeSelection}
                        disabled={!currentCompanyId}
                        fullWidth
                    >
                        <MenuItem value={"VIEW_CASES"}>
                            <Box display="flex" alignItems="center" gap={1}>
                                <Icon>view_stream</Icon>
                                <Typography>View Cases</Typography>
                            </Box>
                        </MenuItem>
                    </Select>
                    <Box mx={1}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                                setCurrentColumn(1);
                                setCurrentCaseId("");
                            }}
                        >
                            New Case
                        </Button>
                    </Box>
                </Box>
                <Box
                    flexGrow={1}
                    display="flex"
                    flexDirection={"column"}
                    width="100%"
                    sx={{ overflowY: "auto" }}
                    // onScroll={handleScroll} // Add the scroll handler here
                >
                    {cases.map((caseItem) => (
                        <Box key={caseItem?.id} width={"100%"}>
                            <CaseItemComponent
                                caseItem={caseItem}
                                selected={currentCaseId === caseItem?.id}
                                handleClick={() => {
                                    setCurrentColumn(1);
                                    setCurrentCaseId(caseItem?.id || "");
                                    console.log(caseItem);
                                }}
                            />
                        </Box>
                    ))}
                </Box>
            </Box>

            {/* Second column */}
            <Box
                display={columnDisplay[1] ? "flex" : "none"}
                flexDirection={"column"}
                width={columnWidths[1] + "%"}
                height={"100%"}
                sx={{ borderRight: "1px solid grey" }}
            >
                <Box
                    display="flex"
                    alignItems={"center"}
                    gap={1}
                    p={1}
                    height={"86px"}
                    sx={{ borderBottom: "1px solid grey" }}
                    justifyContent={"space-between"}
                >
                    <Box display={"flex"} alignItems={"center"} gap={1}>
                        {isMobile && (
                            <IconButton onClick={() => setCurrentColumn(0)}>
                                <Icon>arrow_back</Icon>
                            </IconButton>
                        )}
                        <Avatar />
                        <Typography>
                            {currentCase && currentCase.prospectName}{" "}
                            {currentCase?.admins && (
                                <Typography variant="caption" display="inline" color="textSecondary" ml={1}>
                                    Created by:{currentCase.admins}
                                </Typography>
                            )}
                        </Typography>
                    </Box>
                    <Box>
                        {/* Button to enter third column */}
                        {isMobile && (
                            <Box display="flex" justifyContent="center" p={1}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setCurrentCaseId(currentCaseId); // Ensure the case is selected
                                        // Optionally, you can set currentColumn to 2 if needed
                                        setCurrentColumn(2);
                                    }}
                                    sx={{ width: "100%" }} // Make the button full width
                                >
                                    View Details
                                </Button>
                            </Box>
                        )}
                    </Box>
                    <Box>
                        <WhatsappConnectionComponent
                            whatsappUser={whatsappUser}
                            whatsappSession={whatsappSession}
                            setApiAddress={setApiAddress}
                            setWhatsappSession={setWhatsappSession}
                            setWhatsappPhone={setWhatsappPhone}
                            setSecret={setSecret}
                        />
                    </Box>
                </Box>
                <Box
                    flex={1}
                    display="flex"
                    width="100%"
                    height="100%"
                    sx={{
                        backgroundSize: "cover", // to cover the entire Box area
                        backgroundPosition: "center",
                        overflowY: "auto",
                    }}
                >
                    {getComponent()}
                </Box>
            </Box>

            {/* Third column */}
            <Box
                display={currentCompanyId && currentCaseId ? "flex" : "none"} // Check for both company and case selection
                flexDirection={"column"}
                width={columnWidths[2] + "%"}
                height={"100%"}
            >
                <Box
                    display="flex"
                    alignItems={"center"}
                    gap={1}
                    p={1}
                    py={4}
                    height={"86px"}
                    sx={{ borderBottom: "1px solid grey" }}
                    justifyContent={"space-between"}
                >
                    {isMobile && (
                        <IconButton onClick={() => setCurrentColumn(1)}>
                            <Icon>arrow_back</Icon>
                        </IconButton>
                    )}
                    <Typography>Details</Typography>
                </Box>

                <Box>
                    {/* Display your component or settings here */}
                    <Box p={1} width={"100%"} display="flex" flexDirection={"column"} gap={1}>
                        <Typography sx={{ width: "300px" }}>New Comment: </Typography>
                        <TextField
                            sx={{ width: "100%" }}
                            label="Enter comment"
                            variant="outlined"
                            multiline
                            fullWidth
                            rows={4}
                            value={inputText}
                            onChange={handleInputChange}
                        />

                        <Box>
                            <Box display="flex" sx={{ marginBottom: "10px" }}>
                                <label htmlFor="file-upload">
                                    <input
                                        id="file-upload"
                                        type="file"
                                        accept=".pdf,.doc,.docx,.jpg,.png"
                                        style={{ display: "none" }}
                                        multiple // Add this attribute to allow multiple file selection
                                        onChange={handleFileUpload}
                                    />
                                    <Button
                                        variant="contained"
                                        size="small"
                                        sx={{ width: "100px" }}
                                        component="span"
                                    >
                                        Attachment
                                    </Button>
                                </label>
                            </Box>

                            <Box
                                sx={{
                                    width: "100%",
                                    height: "100px", // Adjust the height as needed
                                    overflowY: "auto",
                                    // border: "1px solid #ccc",
                                    padding: "10px",
                                    borderRadius: "4px",
                                }}
                            >
                                <Box display="flex" flexWrap="wrap" gap="10px">
                                    {uploadFiles.map((file, index) => (
                                        <Box
                                            key={index}
                                            display="flex"
                                            alignItems="center"
                                            sx={{ marginBottom: "5px" }}
                                        >
                                            {file.type.includes("image") ? (
                                                <img
                                                    src={URL.createObjectURL(file)}
                                                    alt={file.name}
                                                    style={{
                                                        width: "30px",
                                                        height: "auto",
                                                        marginRight: "10px",
                                                    }}
                                                />
                                            ) : (
                                                <AttachFileIcon style={{ marginRight: "10px" }} />
                                            )}
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                {file.name}
                                            </Typography>
                                            <IconButton size="small" onClick={() => removeUploadFile(index)}>
                                                <CancelIcon />
                                            </IconButton>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                        <Button
                            sx={{
                                width: { xs: "100%", sm: "auto" },
                                height: "50px",
                                bgcolor: "#26BDC2",
                            }}
                            variant="contained"
                            endIcon={<SendIcon />}
                            onClick={handleAddCaseComment}
                        >
                            Update
                        </Button>
                    </Box>
                </Box>
                <Box>
                    {" "}
                    <Box p={1} width={"100%"} display="flex" flexDirection={"column"} gap={1}>
                        <Typography sx={{ width: "300px" }}>Activity</Typography>
                        <Box
                            sx={{
                                height: "60vh", // Set a fixed height for the comments box
                                padding: "20px",
                                border: "1px solid grey",
                                overflowY: "auto", // Enable vertical scrolling
                            }}
                        >
                            {caseComments.map((comment) => (
                                <Card
                                    key={comment.id} // Unique key for each comment
                                    sx={{
                                        marginBottom: "10px", // Adjusted margin for spacing between comments
                                        padding: "10px",
                                    }}
                                >
                                    <CardContent>
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="flex-start"
                                        >
                                            <Box display="flex" flexDirection="column">
                                                {/* Chip for the timestamp */}
                                                <Chip
                                                    label={new Date(comment.createdAt).toLocaleString()} // Display timestamp
                                                    size="small" // Set chip size to small
                                                    variant="outlined"
                                                    color="primary"
                                                    sx={{ marginBottom: "5px" }} // Add some space below the chip
                                                />
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    sx={{ fontWeight: "bold", fontSize: "1.0rem" }}
                                                >
                                                    {comment.createdByName} {/* Display username */}
                                                </Typography>
                                            </Box>

                                            <Box
                                                display="flex"
                                                flexDirection="column"
                                                flexGrow={1}
                                                sx={{ marginLeft: 2 }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    sx={{ paddingLeft: 0, textAlign: "left" }} // Ensure message is aligned left
                                                >
                                                    {comment.text} {/* Display comment message */}
                                                </Typography>
                                            </Box>

                                            {/* Conditionally Render Edit and Delete Icons */}
                                            {comment.createdBy === user.id && (
                                                <Box display="flex" alignItems="center" ml={2}>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => handleEditComment(comment)}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => handleDeleteComment(comment.id)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Box>
                                            )}
                                        </Box>

                                        {/* Check if there are any files and display them */}
                                        {comment.files && comment.files.length > 0 && (
                                            <Box mt={2}>
                                                <Typography variant="body2" color="textSecondary">
                                                    Attached Files:
                                                </Typography>

                                                <Box>
                                                    <List>
                                                        {comment.files.map((file, index) => (
                                                            <ListItem key={index} disableGutters>
                                                                <Box display="flex" alignItems="center">
                                                                    <ListItemText
                                                                        primary={
                                                                            <Link
                                                                                href={file.downloadURL}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                underline="none"
                                                                                color="primary"
                                                                            >
                                                                                <Typography variant="body2">
                                                                                    {file.name}
                                                                                </Typography>
                                                                            </Link>
                                                                        }
                                                                    />
                                                                    {comment.createdBy === user.id && (
                                                                        <IconButton
                                                                            edge="end"
                                                                            size="small"
                                                                            onClick={() =>
                                                                                deleteUploadFile(
                                                                                    file,
                                                                                    index,
                                                                                    comment.id
                                                                                )
                                                                            }
                                                                        >
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    )}
                                                                </Box>
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </Box>
                                            </Box>
                                        )}
                                    </CardContent>
                                </Card>
                            ))}
                        </Box>
                    </Box>
                </Box>

                {/* Delete confirmation dialog */}
                <Dialog open={deleteDialogOpen} onClose={handleCancelDelete}>
                    <DialogTitle>Confirm Deletion</DialogTitle>
                    <DialogContent>
                        <Typography>Are you sure you want to delete this item?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancelDelete}>Cancel</Button>
                        <Button onClick={handleConfirmDelete} variant="contained" color="error">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={editDialogOpen} onClose={handleCancelEdit}>
                    <DialogTitle>Edit Purchase</DialogTitle>
                    <DialogContent sx={{ maxHeight: "500px" }}>
                        <Box display="flex" flexDirection="column" gap="30px" sx={{ marginTop: "10px" }}>
                            <Box>
                                <Typography
                                    sx={{
                                        width: "200px",
                                        fontWeight: "bold",
                                        textAlign: "left",
                                    }}
                                >
                                    New Comment
                                </Typography>

                                <TextField
                                    size="small"
                                    sx={{ width: "300px" }}
                                    value={newComment}
                                    multiline
                                    rows={4}
                                    onChange={(e) => setNewComment(e.target.value)}
                                ></TextField>
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancelEdit}>Cancel</Button>
                        <Button onClick={handleConfirmEdit} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={deleteFileDialogOpen} onClose={handleCancelDeleteFile}>
                    <DialogTitle>Confirm Deletion</DialogTitle>
                    <DialogContent>
                        <Typography>Are you sure you want to delete this item?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancelDeleteFile}>Cancel</Button>
                        <Button onClick={handleConfirmDeleteFile} variant="contained" color="error">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={deleteCompanyDialogOpen} onClose={() => setDeleteCompanyDialogOpen(false)}>
                    <DialogTitle>Delete Company</DialogTitle>
                    <DialogContent>
                        <Typography>Are you sure you want to delete this company?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDeleteCompanyDialogOpen(false)}>Cancel</Button>
                        <Button onClick={handleConfirmDeleteCompany} color="error">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
}
