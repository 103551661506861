import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import { addDoc, collection, deleteDoc, doc, setDoc } from "firebase/firestore";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import { db } from "../../firebase/firebase-utils";
import { arrayIsEmpty } from "../../utils-functions/arrayIsEmpty";
import { Timestamp } from "firebase-admin/firestore";
import { IVoice } from "../../../functions/src/types/voiceTypes";

export type IVoiceObj = {
    id?: string;
    date: Timestamp | Date;
    name: IVoice | "";
    provider: string;
    gender?: "FEMALE" | "MALE";
};

export default function VoicesComponent({ voices, docName }: { voices: IVoiceObj[]; docName: string }) {
    const [newVoices, setNewVoices] = React.useState<IVoiceObj[]>([]);

    const handleAddNewItem = async () => {
        const newItem: IVoiceObj = {
            date: new Date(),
            name: "",
            provider: "",
        };

        const collectionRef = collection(db, "settings", docName, "voices");
        await addDoc(collectionRef, newItem);
        toast.success("Voice added");
    };

    useEffect(() => {
        if (arrayIsEmpty(voices)) {
            setNewVoices([]);
        } else {
            setNewVoices(voices);
        }
    }, [voices]);

    return (
        <>
            <Box>
                <Button size="small" variant="contained" onClick={handleAddNewItem}>
                    Add Item
                </Button>
                <Box my={2}>
                    <Divider />
                </Box>
            </Box>
            {newVoices.map((voice) => (
                <Box key={voice.id} py={1} width={"100%"}>
                    <SubComponent item={voice} docName={docName} />
                </Box>
            ))}
        </>
    );
}

const SubComponent = ({ item, docName }: { item: IVoiceObj; docName: string }) => {
    const [newItem, setNewItem] = React.useState<IVoiceObj>(item);

    useEffect(() => {
        setNewItem(item);
    }, [item]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
        const { value } = e.target;
        setNewItem((prev) => ({ ...prev, [field]: value }));
    };

    const handleSave = async () => {
        // Do not save if no changes
        if (JSON.stringify(newItem) === JSON.stringify(item)) return;

        try {
            if (!newItem.id) return;

            const docRef = doc(db, "settings", docName, "voices", newItem.id);
            await setDoc(docRef, newItem, { merge: true });
            toast.success("Item saved");
        } catch (err: any) {
            toast.error(err.message);
        }
    };

    const confirmation = useConfirmation();

    const handleDelete = async () => {
        const response = await confirmation("Delete prompt", "Press ok to confirm deletion");
        if (response) {
            try {
                if (!newItem.id) return;
                const docRef = doc(db, "settings", docName, "voices", newItem.id);
                await deleteDoc(docRef);
                toast.success("Item deleted");
            } catch (err: any) {
                toast.error(err.message);
            }
        }
    };

    return (
        <Box>
            <Box display="flex" alignItems={"center"} gap={1} justifyContent={"flex-start"} mb={1}>
                <Typography sx={{ width: "100px" }}>Name: </Typography>
                <TextField
                    sx={{ flex: 1 }}
                    fullWidth
                    size="small"
                    value={newItem.name || ""}
                    onChange={(e) => handleChange(e as any, "name")}
                    onBlur={handleSave}
                />
            </Box>
            <Box display="flex" alignItems={"center"} gap={1} mb={1}>
                <Typography sx={{ width: "100px" }}>Provider: </Typography>
                <TextField
                    sx={{ flex: 1 }}
                    fullWidth
                    size="small"
                    value={newItem.provider || ""}
                    onChange={(e) => handleChange(e as any, "provider")}
                    onBlur={handleSave}
                />
            </Box>
            <Box display="flex" alignItems={"center"} gap={1} mb={1}>
                <Typography sx={{ width: "100px" }}>Gender: </Typography>
                <TextField
                    sx={{ flex: 1 }}
                    fullWidth
                    size="small"
                    value={newItem.gender || ""}
                    onChange={(e) => handleChange(e as any, "gender")}
                    onBlur={handleSave}
                />
            </Box>

            <Box display="flex" alignItems={"center"} gap={1} justifyContent={"flex-end"} mt={1}>
                <Button size="small" variant="contained" onClick={handleDelete} color="error">
                    Delete Item
                </Button>
            </Box>
            <Box my={2}>
                <Divider
                    sx={{
                        borderBottomWidth: "2px", // Adjust thickness here
                        color: "black", // Adjust color here
                    }}
                />
            </Box>
        </Box>
    );
};
