import React, { useContext, useEffect } from "react";
import { IIntentionBlock } from "../../../functions/src/types/intentionType";
import { Box, Button, Chip, Divider, IconButton, Paper, TextField, Typography } from "@mui/material";
import { db } from "../../firebase/firebase-utils";
import { doc, setDoc } from "firebase/firestore";
import { AuthContext } from "../../context/AuthContext";
import { UserType } from "../../../functions/src/types/userTypes";
import toast from "react-hot-toast";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

export default function SubItemComponent({
    subItem,
    setSubItem,
    currentItemId,
    handleGoUp,
    handleGoDown,
}: {
    subItem: IIntentionBlock;
    setSubItem: React.Dispatch<React.SetStateAction<IIntentionBlock | null>>;
    currentItemId: string;
    handleGoUp: (subItem: IIntentionBlock) => void;
    handleGoDown: (subItem: IIntentionBlock) => void;
}) {
    const [newSubItem, setNewSubItem] = React.useState<IIntentionBlock | null>(subItem);

    const { user } = useContext(AuthContext) as { user: UserType };

    useEffect(() => {
        if (subItem) {
            setNewSubItem(subItem);
        } else {
            setNewSubItem(null);
        }
    }, [subItem]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
        if (!newSubItem) return;
        setNewSubItem({ ...newSubItem, [key]: e.target.value });
    };

    const handleSave = async () => {
        if (!newSubItem) return;

        if (JSON.stringify(newSubItem) === JSON.stringify(subItem)) return;

        console.log("Save", newSubItem);
        const docRef = doc(
            db,
            "users",
            user.id,
            "whatsappFlows",
            currentItemId,
            "blocks",
            newSubItem.id
        );

        await setDoc(docRef, newSubItem, { merge: true });
        toast.success("Saved");
    };

    return (
        <Paper>
            <Box display="flex" alignItems="center" gap={1}>
                <Button size="small" onClick={() => setSubItem(newSubItem)}>
                    Select this
                </Button>
                <Typography>id:</Typography>
                <Chip label={newSubItem?.id || ""} size="small" color="info" />
            </Box>

            <Box display={"flex"} flexDirection={"column"} gap={1} p={2}>
                <Box display="flex" justifyContent="flex-start" alignItems={"center"} gap={1}>
                    <Typography sx={{ width: "100px" }}>Title</Typography>
                    <TextField
                        sx={{ flex: 1 }}
                        size="small"
                        fullWidth
                        value={newSubItem?.title}
                        onChange={(e: any) => handleChange(e, "title")}
                        onBlur={handleSave}
                    />
                </Box>
                <Box display="flex" justifyContent="flex-start" alignItems={"center"} gap={1}>
                    <Typography sx={{ width: "100px" }}>Description</Typography>
                    <TextField
                        sx={{ flex: 1 }}
                        size="small"
                        fullWidth
                        value={newSubItem?.description}
                        onChange={(e: any) => handleChange(e, "description")}
                        onBlur={handleSave}
                    />
                </Box>

                <Box display="flex" justifyContent="flex-start" alignItems={"center"} gap={1}>
                    <Typography sx={{ width: "100px" }}>Type</Typography>
                    <TextField
                        size="small"
                        sx={{ flex: 1 }}
                        fullWidth
                        value={newSubItem?.intentionType}
                        onChange={(e: any) => handleChange(e, "intentionType")}
                        onBlur={handleSave}
                    />
                </Box>

                <Box display="flex" justifyContent="flex-start" alignItems={"center"} gap={1}>
                    <IconButton onClick={() => handleGoUp(newSubItem as IIntentionBlock)}>
                        <ArrowCircleUpIcon />
                    </IconButton>
                    <IconButton sx={{ marginRight: 2 }}>
                        <ArrowCircleDownIcon onClick={() => handleGoDown(newSubItem as IIntentionBlock)} />
                    </IconButton>
                    <Typography>Previous Id:</Typography>
                    <Typography>{newSubItem?.previousBlockId || "none"}</Typography>
                    <Typography>{` | `}</Typography>
                    <Typography>Next Id:</Typography>
                    <Typography>{newSubItem?.nextBlockId || "none"}</Typography>
                </Box>
            </Box>
        </Paper>
    );
}
