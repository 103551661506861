import React from "react";
import { Container } from "@mui/material";
import { GridContainer, GridDivider, GridFlexBox, Name, Title } from "../../themes/themes";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

export default function DjcFunnelsPage() {
    return (
        <Container maxWidth="none">
            <GridContainer>
                <GridFlexBox>
                    <Title>Funnels</Title>
                </GridFlexBox>
                <GridDivider />
                <GridFlexBox>
                    <Name fs14>
                        If you have subscribed to Funnels, you would have got a separate login for the system
                    </Name>
                </GridFlexBox>
                <GridFlexBox mt8>
                    <a href="https://app.djcsystem.com" target="_blank" rel="noopener noreferrer">
                        <Button variant="outlined">funnels</Button>
                    </a>
                </GridFlexBox>
                <GridFlexBox mt8 gap={"8px"}>
                    <Name>If you have not subscribed, you can subcribe</Name>
                    <Link to={"/stripe-checkout-page"}>here</Link>
                </GridFlexBox>
            </GridContainer>
        </Container>
    );
}
