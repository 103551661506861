import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Container, Divider, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import { addDoc, collection, doc, onSnapshot } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";

const REACT_APP_INSTAGRAMAPPID = process.env.REACT_APP_INSTAGRAMAPPID;

const permission_scope = ["business_basic", "business_manage_messages"].join("%2C");

export default function ConnectInstagramPage() {
    return (
        <div>
            <LoginPage />
        </div>
    );
}

const LoginPage = () => {
    // State to hold the returned parameters
    const [errorInfo, setErrorInfo] = useState({
        error: "",
        errorCode: "",
        errorDescription: "",
        errorReason: "",
    });
    const [code, setCode] = useState("");
    const [loading, setLoading] = useState(false);
    const [rawInstagramPageId, setRawInstagramPageId] = useState("");
    const [instagramPageId, setInstagramPageId] = useState("");
    const [instagramPage, setInstagramPage] = useState(null);
    const [message, setMessage] = useState("");

    const { user } = useContext(AuthContext);

    useEffect(() => {
        if (!instagramPageId) return;

        const docRef = doc(db, "pages", instagramPageId);
        const unsubscribe = onSnapshot(docRef, (snap) => {
            const data = mapDocSnapshot(snap);
            console.log(data);
            setInstagramPage(data);
            setLoading(false);
        });

        return unsubscribe;
    }, [instagramPageId]);

    useEffect(() => {
        if (!rawInstagramPageId) return;

        const docRef = doc(db, "rawInstagramPages", rawInstagramPageId);

        const unsubscribe = onSnapshot(docRef, (snap) => {
            const data = mapDocSnapshot(snap);
            if (data.pageId) {
                setInstagramPageId(data.pageId);
            }

            if (data.message) {
                setMessage(data.message);
            }
        });

        return unsubscribe;
    }, [rawInstagramPageId]);

    useEffect(() => {
        console.log("Checking code: ", code);
        if (!code) return;
        if (!user) return;

        const addInstagramPage = async () => {
            const hostname = window.location.hostname;

            const collectionRef = collection(db, "rawInstagramPages");
            const data = {
                code: code,
                createdBy: user.id,
                date: new Date(),
                hostname,
            };
            setLoading(true);
            const { id } = await addDoc(collectionRef, data);
            console.log("Document written with ID: ", id);
            setRawInstagramPageId(id);
        };

        addInstagramPage();
    }, [code]);

    const handleLogin = () => {
        // Dynamically get the current URL
        console.log(window.location.hostname);
        const currentUrl = `https://${window.location.hostname}/connect-instagram-page`;

        // Construct the OAuth URL for Instagram/Facebook with the current URL as the redirect_uri
        const authUrl = `https://www.instagram.com/oauth/authorize?&client_id=${REACT_APP_INSTAGRAMAPPID}&redirect_uri=${currentUrl}&response_type=code&scope=${permission_scope}`;

        // Open the OAuth URL in a new window or tab
        window.open(authUrl, "_blank");
    };

    useEffect(() => {
        const parseUrlParameters = () => {
            const url = window.location.href;
            const urlObj = new URL(url);

            const code = urlObj.searchParams.get("code");

            const error = urlObj.searchParams.get("error");
            const errorCode = urlObj.searchParams.get("error_code");
            const errorDescription = urlObj.searchParams.get("error_description")?.replace(/\+/g, " "); // Replace '+' with space
            const errorReason = urlObj.searchParams.get("error_reason");

            if (code) {
                setCode(code);
                console.log(code);
            }

            if (error) {
                setErrorInfo({
                    error,
                    errorCode,
                    errorDescription,
                    errorReason,
                });
            }
        };

        parseUrlParameters();
    }, []);

    return (
        <Container maxWidth="sm" style={{ textAlign: "center", marginTop: "50px" }}>
            <LoadingButton
                variant="contained"
                color="primary"
                onClick={() => handleLogin("instagram")}
                style={{ marginRight: "10px" }}
                disabled={loading}
                loading={loading}
            >
                Login with Instagram
            </LoadingButton>
            <Box m={2}>
                <Divider />
            </Box>
            <Typography variant="h4" gutterBottom>
                Login Status
            </Typography>
            {errorInfo.error && (
                <div>
                    <Typography variant="subtitle1">Error: {errorInfo.error}</Typography>
                    <Typography variant="subtitle1">Error Code: {errorInfo.errorCode}</Typography>
                    <Typography variant="subtitle1">Description: {errorInfo.errorDescription}</Typography>
                    <Typography variant="subtitle1">Reason: {errorInfo.errorReason}</Typography>
                </div>
            )}

            {message && (
                <Typography variant="body2" color={blue[500]}>
                    {message}
                </Typography>
            )}

            {instagramPage && (
                <Box>
                    <Typography variant="h6">Instagram Page</Typography>
                    <Typography variant="body1">Page ID: {instagramPage.id}</Typography>
                    <Typography variant="body1">Page Name: {instagramPage.name}</Typography>
                </Box>
            )}
        </Container>
    );
};
