import { addDoc, collection, doc, getDoc } from "firebase/firestore";
import { IResetPassword } from "../../functions/src/apis/Misc/resetPassword";
import { db } from "../firebase/firebase-utils";
import { notification } from "../utils-functions/notification";

export default async function resetPassword(email: string) {
    if (email) {
        email = email.trim().toLowerCase();
    }

    try {
        const userRef = doc(db, "users", email);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
            const emailCollectionRef = collection(db, "resetPassword");

            const data: IResetPassword = {
                date: new Date(),
                email: email,
                hostname: window.location.hostname,
            };

            await addDoc(emailCollectionRef, data);

            console.log("Reset email sent");
            notification("Reset password", "Email sent. Please check your email", "success");
            return true;
        } else {
            notification("Cannot find email", "Please double check your email", "warning");
            return false;
        }
    } catch (err) {
        console.log(err);
    }
}
