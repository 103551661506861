import { Box, CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import { blue, green } from "@mui/material/colors";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import convertDate from "../../functions/common-functions/convertDate";
import { GridContainer, GridFlexBox, Name } from "../../themes/themes";
import { arrayIsEmpty } from "../../utils-functions/arrayIsEmpty";
import formatDate from "./../../functions/common-functions/formatDate";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

export default function LeadActionsComponent({ lead, leadsMode, limit, setOpenCard, setLead }) {
    const [actions, setActions] = useState([]);
    const [loading, setLoading] = useState(false);

    const { user } = useContext(AuthContext);

    useEffect(() => {
        // if (lead.lastAction) {
        //     if (Array.isArray(lead.lastAction)) {
        //         setActions(lead.lastAction);
        //     } else {
        //         setActions([lead.lastAction]);
        //     }
        // }else{
        //     console.log("leads no last action:", lead);
        // }

        if (lead) {
            const collectionRef = collection(db, "leads", lead.id, "actions");
            const q = query(collectionRef, orderBy("created", "desc"));
            setLoading(true);
            const unsubscribe = onSnapshot(q, (snapShot) => {
                let actions = snapShot.docs.map((doc) => {
                    const data = doc.data();
                    return {
                        ...data,
                        leadId: lead.id,
                        id: doc.id,
                        created: convertDate(data.created),
                        userName: data.userName || data.user?.split("@")?.[0] || "",
                    };
                });
                setActions(actions);
                setLoading(false);
            });
            return unsubscribe;
        }
    }, [lead]);

    const displayAction = (lead, action) => {
        if (leadsMode === "SOURCE_LEADS") {
            return true;
        }
        if (action.user === user.email) return true;
        if (lead.closers) {
            lead.closers.forEach((closer) => {
                if (closer === user.email) {
                    return true;
                }
            });
        }
    };

    const decideColor = (action) => {
        if (action.action === "Appointment") {
            return green[500];
        } else {
            return blue[500];
        }
    };

    const [displayActions, moreActions] = useMemo(() => {
        let array = [];
        actions.forEach((action) => {
            if (displayAction(lead, action)) {
                array.push(action);
            }
        });
        let moreActions = false;
        if (array.length > limit) {
            moreActions = array.length - limit;
        }
        return [array, moreActions];
    }, [actions, limit]);

    const handleClickMoreActions = () => {
        setLead(lead);
        setOpenCard(true);
    };

    return (
        <Box sx={{ width: "100%", height: "54px", overflowY: "auto" }}>
            {loading && (
                <Box m={1}>
                    <CircularProgress size="15px" />
                </Box>
            )}
            {displayActions.slice(0, limit).map((action, i) => {
                return (
                    <Box
                        display={"flex"}
                        key={i}
                        onClick={() => {
                            console.log(actions);
                        }}
                        gap={1}
                        alignItems={"flex-start"}
                    >
                        <Box display={"flex"} flexDirection={"column"}>
                            <Typography
                                variant="subtitle2"
                                style={{ fontWeight: "bold", color: decideColor(action) }}
                            >
                                {action.userName}
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                style={{ color: decideColor(action), fontSize: "9px" }}
                            >
                                {formatDate(convertDate(action.created), true, false)}
                            </Typography>
                        </Box>
                        <Box display={"flex"} flexDirection={"column"} flex={1}>
                            <Typography
                                variant="subtitle2"
                                style={{ fontWeight: "bold", color: decideColor(action) }}
                            >
                                {action.action}
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                style={{ whiteSpace: "normal", color: decideColor(action) }}
                            >
                                {action.remark}
                            </Typography>
                            {!arrayIsEmpty(action.files) &&
                                action.files.map((file) => (
                                    <React.Fragment key={file.name}>
                                        <a
                                            href={file.downloadURL}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ textDecoration: "none" }} // Optional: Remove the underline styling from the link
                                        >
                                            <Typography variant="subtitle2" style={{ whiteSpace: "normal" }}>
                                                {file.name}
                                            </Typography>
                                        </a>
                                    </React.Fragment>
                                ))}
                        </Box>
                    </Box>
                );
            })}
            {moreActions && (
                <GridContainer>
                    <GridFlexBox fs>
                        <IconButton style={{ padding: 0 }} size="small" onClick={handleClickMoreActions}>
                            <Name color={blue[500]}>{`${moreActions} more actions...`}</Name>
                        </IconButton>
                    </GridFlexBox>
                </GridContainer>
            )}
        </Box>
    );
}
