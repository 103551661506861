import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { doc, Timestamp, updateDoc } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { db } from "../../firebase/firebase-utils";

const ROW_NUMBER = [
    { field: "title", number: 1 },
    { field: "description", number: 3 },
    { field: "systemPrompts", number: 6 },
    { field: "systemPrompt", number: 6 },
    { field: "userPrompts", number: 6 },
    { field: "userPrompt", number: 6 },
    { field: "prompt", number: 6 },
];

export default function ItemDetailsComponent({ item }: { item: Record<string, any> }) {
    // Local state for all item fields
    const [state, setState] = useState<Record<string, any>>({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogKey, setDialogKey] = useState<string | null>(null);
    const [dialogValue, setDialogValue] = useState<string>("");

    // Sync state with the item prop when it changes
    useEffect(() => {
        setState({ ...item });
    }, [item]);

    const handleBooleanChange = async (key: string) => {
        const docRef = doc(db, "settings", item.id);
        await updateDoc(docRef, { [key]: !state[key] });
        toast.success("Field updated");
    };

    const handleStringChange = (key: string, value: string) => {
        setState((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const handleDateChange = async (key: string, value: Dayjs | null) => {
        if (value) {
            const docRef = doc(db, "settings", item.id);
            const date = value.toDate();
            await updateDoc(docRef, { [key]: date });
            toast.success("Field updated");
        }
    };

    const handleDialogOpen = (key: string) => {
        setDialogKey(key);
        setDialogValue(JSON.stringify(state[key], null, 2)); // Pre-fill dialog with stringified array
        setDialogOpen(true);
    };

    const handleDialogSave = async () => {
        if (dialogKey) {
            try {
                const parsedValue = JSON.parse(dialogValue);
                const docRef = doc(db, "settings", item.id);
                await updateDoc(docRef, { [dialogKey]: parsedValue });
                toast.success("Field updated");
                setDialogOpen(false);
            } catch (error) {
                alert("Invalid JSON format. Please correct and try again.");
            }
        }
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleSave = async (key: string) => {
        if (state[key] !== item[key]) {
            const docRef = doc(db, "settings", item.id);
            await updateDoc(docRef, { [key]: state[key] });
            toast.success("Field updated");
        }
    };

    const getRowNumber = (key: string) => {
        const rowInfo = ROW_NUMBER.find((row) => row.field === key);
        return rowInfo ? rowInfo.number : 1; // Default to 1 if not found
    };

    const checkAndDisplay = (key: string, value: any) => {
        if (value === null || value === undefined) {
            return null; // Skip null or undefined values
        }

        if (key === "id") {
            return null; // Skip id field
        }

        if (typeof value === "string") {
            return (
                <Box display="flex" flexDirection="column" sx={{ marginBottom: 2 }}>
                    <Typography variant="h6">{key}:</Typography>
                    <TextField
                        multiline
                        rows={getRowNumber(key)}
                        value={state[key] || ""}
                        onChange={(e) => handleStringChange(key, e.target.value)}
                        onBlur={() => {
                            handleSave(key);
                        }}
                        variant="outlined"
                        fullWidth
                    />
                </Box>
            );
        }

        if (typeof value === "number") {
            return (
                <>
                    <Typography variant="h6">{key}:</Typography>
                    <Typography variant="body1">{value.toString()}</Typography>
                </>
            );
        }

        if (typeof value === "boolean") {
            return (
                <Box display="flex" alignItems="center">
                    <Typography variant="h6" sx={{ marginRight: 2 }}>
                        {key}:
                    </Typography>
                    <Switch checked={state[key]} onChange={() => handleBooleanChange(key)} color="primary" />
                </Box>
            );
        }

        if (Array.isArray(value)) {
            return (
                <Box display="flex" alignItems="center" sx={{ marginBottom: 2 }}>
                    <Typography variant="h6" sx={{ marginRight: 2 }}>
                        {key}:
                    </Typography>
                    <Typography variant="body1" sx={{ marginRight: 2 }}>
                        {JSON.stringify(value, null, 2)}
                    </Typography>
                    <Button variant="outlined" onClick={() => handleDialogOpen(key)}>
                        Edit
                    </Button>
                </Box>
            );
        }

        if (value instanceof Timestamp) {
            return (
                <Box display="flex" flexDirection="column" sx={{ marginBottom: 2 }}>
                    <Typography variant="h6">{key}:</Typography>
                    <DatePicker
                        value={dayjs(state[key].toDate())} // Convert Timestamp to Date
                        onChange={(newValue) => handleDateChange(key, newValue)}
                    />
                </Box>
            );
        }

        if (typeof value === "object") {
            return (
                <>
                    <Typography variant="h6">{key}:</Typography>
                    <Box sx={{ paddingLeft: 2 }}>
                        {Object.keys(value).map((nestedKey) => checkAndDisplay(nestedKey, value[nestedKey]))}
                    </Box>
                </>
            );
        }

        return null; // Skip unsupported types
    };

    const object = useMemo(() => {
        if (!item) return {};

        const prioritizedKeys = [
            "date",
            "title",
            "description",
            "systemPrompts",
            "userPrompts",
            "systemPrompt",
            "userPrompt",
        ];
        const otherKeys = Object.keys(item)
            .filter((key) => !prioritizedKeys.includes(key))
            .sort(); // Sort alphabetically

        const reorderedKeys = [...prioritizedKeys, ...otherKeys];

        // Create a new object with reordered keys
        const reorderedObject: Record<string, any> = {};
        reorderedKeys.forEach((key) => {
            if (item[key] !== undefined) {
                reorderedObject[key] = item[key];
            }
        });

        return reorderedObject;
    }, [item]);

    return (
        <>
            {item && Object.keys(object).length > 0 ? (
                Object.keys(object).map((key) => (
                    <Box key={key} sx={{ marginBottom: 2 }}>
                        {checkAndDisplay(key, state[key])}
                    </Box>
                ))
            ) : (
                <Typography>No details available.</Typography>
            )}

            {/* Dialog for editing arrays */}
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                fullWidth
                maxWidth="lg"
                sx={{
                    "& .MuiDialog-paper": {
                        height: "600px", // Custom height
                        maxWidth: "100%", // Disable predefined max width restrictions
                        maxHeight: "none", // Disable predefined max height restrictions
                    },
                }}
            >
                <DialogTitle>Edit Array</DialogTitle>
                <DialogContent>
                    <TextField
                        multiline
                        rows={20}
                        fullWidth
                        value={dialogValue}
                        onChange={(e) => setDialogValue(e.target.value)}
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button variant="contained" onClick={handleDialogSave}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
