import { Box, Grid, IconButton, Paper, Typography } from "@mui/material";
import { collection, deleteDoc, doc, setDoc } from "firebase/firestore";
import React from "react";
import { db } from "../../firebase/firebase-utils";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import formatDate from "../../functions/common-functions/formatDate";
import { UserType } from "../../../functions/src/types/userTypes";
import TelegramIcon from "@mui/icons-material/Telegram";

interface MemberDetailsProps {
    user: UserType;
    checkUser: (user: UserType) => void;
}

export default function MemberDetails({ user, checkUser }: MemberDetailsProps) {
    const confirmation = useConfirmation();

    const handleDeleteUser = async (user: UserType) => {
        const docRef = doc(db, "users", user.id);
        const response = await confirmation("Delete user", "Confirm?");
        if (response) {
            const deletedDocRef = doc(db, "deletedUsers", user.id);
            await setDoc(deletedDocRef, user, { merge: true });
            await deleteDoc(docRef);
            console.log(`${user.displayName} deleted`);
        }
    };

    return (
        <Paper sx={{ padding: [1, 2] }}>
            <Box display="flex" justifyContent="space-between">
                <Box>
                    <Grid container spacing={[0, 1]}>
                        <Grid item xs={6} display="flex" justifyContent="flex-start">
                            <Typography variant="body2" component="span">
                                📅
                            </Typography>
                            <Typography ml={[0, 1]}>{formatDate(user.date)}</Typography>
                        </Grid>
                        <Grid item xs={11} display="flex" justifyContent="flex-start">
                            <Typography variant="body2" onClick={() => checkUser(user)}>
                                📝
                            </Typography>
                            <Typography ml={[0, 1]}>{user.displayName}</Typography>
                        </Grid>
                        <Grid item xs={1} display="flex" justifyContent="flex-end">
                            {user.role === "Super Admin" && (
                                <IconButton onClick={() => handleDeleteUser(user)} size="small">
                                    <Typography variant="body2">🗑️</Typography>
                                </IconButton>
                            )}
                        </Grid>
                        <Grid item xs={12} display="flex" justifyContent="flex-start">
                            <Typography variant="body2" component="span">
                                📧
                            </Typography>
                            <Typography ml={[0, 1]} sx={{ wordWrap: "break-word", wordBreak: "break-word" }}>
                                {user.email}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} display="flex" justifyContent="flex-start">
                            <Typography variant="body2" component="span">
                                📱
                            </Typography>
                            <Typography ml={[0, 1]}>{user.phone}</Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Grid container spacing={[0, 1]}>
                        <Grid item xs={12} display="flex" justifyContent="flex-start">
                            <TelegramIcon />
                            <Typography ml={[0, 1]} sx={{ wordWrap: "break-word", wordBreak: "break-word" }}>
                                {user.chatId ? `Telegram ${user.chatId}` : ""}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} display="flex" justifyContent="flex-start">
                            <Typography variant="body2" component="span">
                                🌳
                            </Typography>
                            <Typography ml={[0, 1]} sx={{ wordWrap: "break-word", wordBreak: "break-word" }}>
                                {user.addOn || "none"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} display="flex" justifyContent="flex-start">
                            <Typography variant="body2" component="span">
                                👥
                            </Typography>
                            <Typography ml={[0, 1]} sx={{ wordWrap: "break-word", wordBreak: "break-word" }}>
                                Referral: {user.referral || ""}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Paper>
    );
}
