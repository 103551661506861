interface Name {
    [key: string]: string | undefined;
    phone: string;
    file?: any;
    status?: string | undefined;
}

interface PollObj {
    pollName: string;
    pollOptions: string[];
}

interface User {
    id: string;
    displayName: string;
}

export default async function sendMessageToNames(
    names: Name[],
    message: string,
    from: string,
    setNames: React.Dispatch<React.SetStateAction<Name[]>>,
    delayTime = 1000,
    isTerminated: React.MutableRefObject<boolean>,
    files: any[],
    apiAddress: string,
    secret: string,
    pollObj: PollObj | null,
    user: User
): Promise<string[]> {
    try {
        const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
        console.log(from);
        const statusArray: string[] = [];
        for (const [i, item] of names.entries()) {
            if (isTerminated.current) {
                break;
            }
            let newMessage = message;

            for (const key of Object.keys(item)) {
                const regex = new RegExp(`{${key}}`, "g");
                newMessage = newMessage.replace(regex, item[key] || "");
            }

            let response;

            if (!files.length && !item.file) {
                response = await sendMessage(item.phone, newMessage, from, apiAddress, secret, user);
            } else if (item.file) {
                response = await sendAttachments({
                    number: item.phone,
                    files: item.file,
                    from,
                    caption: newMessage,
                    apiAddress,
                    secret,
                    sender: user.displayName,
                    type: 2,
                });
            } else {
                response = await sendAttachments({
                    number: item.phone,
                    files,
                    sender: user.displayName,
                    caption: newMessage,
                    apiAddress,
                    from,
                    secret,
                });
            }

            // Added poll handling
            if (pollObj?.pollOptions?.length) {
                const pollResponse = await sendPoll(item.phone, from, apiAddress, secret, pollObj);
                console.log("pollResponse:", pollResponse);

                if (!pollResponse.status) {
                    response = pollResponse;
                }
            }

            let status = "failed";

            if (response.status) {
                status = "sent";
            } else if (response.message === "The number is not registered") {
                status = "bad number";
            }

            statusArray.push(status);
            setNames((prevState) => {
                const newNames = [...prevState];
                newNames[i].status = status;
                return newNames;
            });

            if (i < names.length - 1) {
                await delay(delayTime);
            }
        }

        return Promise.resolve(statusArray);
    } catch (err) {
        console.log(err);
        return [];
    }
}

const sendPoll = async (
    number: string,
    from: string,
    apiAddress: string,
    secret: string,
    pollObj: PollObj
) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestBody = JSON.stringify({
        number: formatPhoneNumber(number),
        from: from,
        secret,
        pollName: pollObj.pollName,
        pollOptions: pollObj.pollOptions,
    });

    const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: requestBody,
        redirect: "follow",
    };

    try {
        const response = await fetch(`${apiAddress}send-poll-message`, requestOptions);
        const result = await response.json();
        return result;
    } catch (error) {
        console.log("error", error);
        return { status: false };
    }
};

async function sendMessage(
    number: string,
    message: string,
    from: string,
    apiAddress: string,
    secret: string,
    user: User,
    options = {}
) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const metaData = { userId: user.id || "", userName: user.displayName || "" };

    const requestBody = JSON.stringify({
        number: formatPhoneNumber(number),
        message: message,
        from: from,
        secret,
        metaData,
        ...options,
    });

    const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: requestBody,
        redirect: "follow",
    };

    try {
        const response = await fetch(`${apiAddress}send-message`, requestOptions);
        const result = await response.json();
        return result;
    } catch (error) {
        console.log("error", error);
        return { status: false };
    }
}

const sendAttachments = async ({
    number,
    files,
    from,
    caption,
    apiAddress,
    secret,
    sender,
    type = 1,
}: {
    number: string;
    files: any;
    from: string;
    caption: string;
    apiAddress: string;
    secret: string;
    sender: string;
    type?: number;
}) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let requestBody;

    if (type === 2) {
        requestBody = JSON.stringify({
            number: formatPhoneNumber(number),
            caption: caption,
            from: from,
            downloadURL: files,
            secret,
            sender,
        });
    } else {
        requestBody = JSON.stringify({
            number: formatPhoneNumber(number),
            caption: caption,
            from: from,
            downloadURL: files[0].downloadURL,
            filename: files[0].name,
            mimetype: files[0].type,
            fileSize: files[0].size,
            secret,
            sender,
        });
    }

    const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: requestBody,
        redirect: "follow",
    };

    try {
        const response = await fetch(`${apiAddress}send-image-url-message`, requestOptions);
        const result = await response.json();
        return result;
    } catch (error) {
        console.log("error", error);
        return { status: false };
    }
};

const createGroup = async ({
    from,
    subject,
    participants,
    apiAddress,
    secret,
}: {
    from: string;
    subject: string;
    participants: string[];
    apiAddress: string;
    secret: string;
}) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestBody = JSON.stringify({
        from,
        subject,
        participants,
        secret,
    });

    const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: requestBody,
        redirect: "follow",
    };

    try {
        const response = await fetch(`${apiAddress}create-group`, requestOptions);
        const result = await response.json();
        return result;
    } catch (error) {
        console.log("error", error);
        return { status: false };
    }
};

export { sendMessage, sendAttachments, createGroup };

function formatPhoneNumber(phone: string): string {
    if (phone.includes("@g.us")) {
        return phone;
    }

    let cleanedPhone = phone.replace(/[^0-9]/g, "");

    if (cleanedPhone[0] === "0") {
        cleanedPhone = "6" + cleanedPhone;
    }
    return cleanedPhone;
}
