import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";
import { collection, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ReactFlow, {
    Background,
    Controls,
    MarkerType,
    MiniMap,
    ReactFlowProvider,
    addEdge,
    applyNodeChanges,
    useEdgesState,
    useNodesState,
    useReactFlow,
} from "reactflow";
import "reactflow/dist/style.css";
import useNotification from "../../context-utils/NotificationContext";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import mapSnapshot from "../../utils-functions/mapSnapshot";
import BlockNode from "./BlockNode";
import CustomEdge from "./CustomEdge";
import FlowContext from "./FlowContext";
import PollNode from "./PollNode";
import LabelNode from "./LabelNode";
import StopFlowNode from "./StopFlowNode";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Icon, IconButton, LinearProgress, Menu, MenuItem, Typography } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import {
    addBlockRelation,
    readBlock,
    readBlockRelation,
    readBlockRelationById,
    removeBlockRelation,
    removeBlockRelationById,
    updateAllSequence999,
    updateBlock,
} from "./firebaseCall.js";
import toast from "react-hot-toast";
import SubItemDetailsComponent from "../IntentionsPage/SubItemDetailsComponent";
import IntentionNode from "./IntentionNode";

const initialNodes = [];

// the styling of edge
// const edgeLabelStyle = {
//     labelBgPadding: [8, 4],
//     labelBgBorderRadius: 4,
//     labelBgStyle: { fill: "transparent" },
//     labelStyle: { fill: "#fff" },
//     markerEnd: {
//         type: MarkerType.ArrowClosed,
//         width: 20,
//         height: 20,
//         color: "#FF0072",
//     },
//     style: {
//         strokeWidth: 3,
//         stroke: "#FF0072",
//     },
//     animated: true,
//     zIndex: 10000,
// };

const initialEdges = [];
const nodeTypes = {
    BlockNode: BlockNode,
    PollNode: PollNode,
    LabelNode: LabelNode,
    StopFlowNode: StopFlowNode,
    IntentionNode: IntentionNode,
};
const collectionName = "whatsappFlows";

function Flow({ flow, updateTrigger, setIsDeleting, isDeleting, openDrawerFunction }) {
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const [refresh, setRefresh] = useState(0);
    const [firstNode, setFirstNode] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [blockMenu, setBlockMenu] = useState(null);
    const [loading, setLoading] = useState(true);
    const [edgeVisible, setEdgeVisible] = useState(true);
    const { user } = useContext(AuthContext);

    const notify = useNotification();
    const reactFlowInstance = useReactFlow();

    const edgeLabelStyle = useMemo(
        () => ({
            labelBgPadding: [8, 4],
            labelBgBorderRadius: 4,
            labelBgStyle: { fill: "transparent" },
            labelStyle: { fill: "#fff" },
            markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 20,
                height: 20,
                color: "#FF0072",
            },
            style: {
                strokeWidth: 3,
                stroke: "#FF0072",
            },
            animated: true,
            zIndex: edgeVisible ? 10000 : 0,
        }),
        [edgeVisible]
    );

    const handleVisibilityToggle = () => {
        setEdgeVisible(!edgeVisible);
        // Update all existing edges with new zIndex
        setEdges((currentEdges) =>
            currentEdges.map((edge) => ({
                ...edge,
                zIndex: !edgeVisible ? 10000 : 0,
            }))
        );
    };

    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    const onConnect = useCallback(
        async (params) => {
            console.log("onconnect");
            const { source, target, sourceHandle, targetHandle } = params;

            // Get the latest block data directly from Firestore
            const sourceBlockDoc = await getDoc(
                doc(db, "users", user.id, collectionName, flow.id, "blocks", source)
            );
            const targetBlockDoc = await getDoc(
                doc(db, "users", user.id, collectionName, flow.id, "blocks", target)
            );

            if (!sourceBlockDoc.exists() || !targetBlockDoc.exists()) {
                notify("One or both nodes no longer exist.", "error");
                return;
            }

            const sourceNode = { id: source, ...sourceBlockDoc.data() };
            const targetNode = { id: target, ...targetBlockDoc.data() };

            // this part for make intention node only can have 1 edge output.
            if (
                (sourceNode.blockType === "intention" || sourceNode.data?.blockType === "intention") &&
                Array.isArray(sourceNode.targetNodeId) &&
                sourceNode.targetNodeId?.length > 0
            ) {
                notify("Intention node can only have 1 edge output.", "error");
                return;
            }

            if (sourceNode === targetNode) {
                notify("Looping the same node is not allowed.", "error");
                return;
            }

            if (sourceNode.parentNode === (targetNode && (targetNode?.id || targetNode?.blockId))) {
                notify("Poll node cannot target back to main node.", "error");
                return;
            }

            const edgeExists = edges.some((edge) => edge.source === source && edge.target === target);

            if (edgeExists) {
                notify("This connection already exists.", "error");
                return;
            }

            let relationObj = {
                sourceNodeId: sourceNode.id,
                targetNodeId: targetNode.id,
                sourceHandleId: sourceHandle,
                sourceBlockType: sourceNode.blockType ? sourceNode.blockType : "",
                flowId: flow.id,
            };

            const existingBlockRelations = await readBlockRelation(user, flow);

            const isDuplicate = existingBlockRelations.some(
                (relation) =>
                    relation.sourceNodeId === relationObj.sourceNodeId &&
                    relation.targetNodeId === relationObj.targetNodeId
            );

            if (isDuplicate) {
                notify("This connection already exists. No duplication allowed.", "error");
                return;
            }

            const newBlkRelationId = await addBlockRelation(user, flow, relationObj);

            let arrTargetNodeIds = [];
            if (sourceNode.targetNodeId) {
                arrTargetNodeIds = [...sourceNode.targetNodeId];
                arrTargetNodeIds.push(targetNode.id);
            } else {
                arrTargetNodeIds = [targetNode.id];
            }

            let blockObj = {
                targetNodeId: arrTargetNodeIds,
            };

            await updateBlock(user, flow, sourceNode.id, blockObj);

            // Update local nodes state with new targetNodeId
            setNodes((prevNodes) =>
                prevNodes.map((node) => {
                    if (node.id === sourceNode.id) {
                        return {
                            ...node,
                            data: {
                                ...node.data,
                                targetNodeId: arrTargetNodeIds,
                            },
                            targetNodeId: arrTargetNodeIds,
                        };
                    }
                    return node;
                })
            );

            const newEdge = {
                ...params,
                id: newBlkRelationId,
                type: "custom-edge",
                ...edgeLabelStyle,
            };

            setEdges((eds) => addEdge(newEdge, eds));
        },
        [setEdges, edges, user, flow, edgeLabelStyle]
    );

    const handleCreateNode = async () => {
        // when create, all name to set as Node Name. after that user will need to 1 by 1 reenter the name for the node.
        try {
            if (!reactFlowInstance) {
                console.error("React Flow instance is not available");
                return;
            }

            //set the initial location of new node. this have to run first cause need to save into new node database.
            const viewPortCenter = reactFlowInstance.screenToFlowPosition({
                x: window.innerWidth / 2,
                y: window.innerHeight / 2,
            });

            const collectionRef = collection(db, "users", user.id, collectionName, flow.id, "blocks");

            //to check if inside blocks there is no record.this part is to cater system migrating where previous system is using orderId for sequencing and 0 means that the block is the first item in the flow.
            const q = query(collectionRef, where("blockType", "==", "sequential"));

            const snapshot = await getDocs(q);

            let tempOrderId = snapshot.empty ? 0 : 999;
            ///need to add checker where if this is the first node, set orderId = 0
            const docRef = doc(collectionRef);

            await setDoc(
                docRef,
                {
                    id: docRef.id,
                    blockId: docRef.id, // Save the document ID
                    name: "New Node Name",
                    createdBy: user.id,
                    status: "active",
                    flowId: flow.id,
                    count: 0,
                    time: 0,
                    timeUnit: "minutes",
                    orderId: tempOrderId,
                    message: "",
                    position: {
                        x: viewPortCenter.x,
                        y: viewPortCenter.y,
                    },
                    updatedDate: new Date(),
                    blockType: "sequential",
                    date: new Date(),
                    type: "BlockNode",
                    functionItems: [],
                    arrPollItems: [],
                    reactFlowStatus: true,
                },
                { merge: true }
            );

            const data = {
                id: docRef.id,
                blockId: docRef.id,
                type: "BlockNode",
                position: {
                    x: viewPortCenter.x,
                    y: viewPortCenter.y,
                },
                data: {
                    name: "New Node Name",
                    id: docRef.id,
                    blockId: docRef.id,
                    createdBy: user.id,
                    status: "active",
                    flowId: flow.id,
                    count: 0,
                    time: 0,
                    timeUnit: "minutes",
                    orderId: tempOrderId,
                    message: "",
                    updatedDate: new Date(),
                    blockType: "sequential",
                    date: new Date(),
                    functionItems: [],
                    arrPollItems: [],
                    reactFlowStatus: true,
                },
            };

            console.log("handleCreateNode x:", viewPortCenter.x);
            console.log("handleCreateNode y:", viewPortCenter.y);

            setNodes((nds) => [...nds, data]);
            // setRerender(!rerender);
        } catch (err) {
            console.log("ReactFlowPage.jsx:", err);
        } finally {
            setBlockMenu(null);
        }
    };

    const handleCreateMetaCatalogNode = async () => {
        try {
            if (!reactFlowInstance) {
                console.error("React Flow instance is not available");
                return;
            }

            const viewPortCenter = reactFlowInstance.screenToFlowPosition({
                x: window.innerWidth / 2,
                y: window.innerHeight / 2,
            });

            const collectionRef = collection(db, "users", user.id, collectionName, flow.id, "blocks");
            const q = query(collectionRef, where("blockType", "==", "sequential"));
            const snapshot = await getDocs(q);
            let tempOrderId = 999;

            // Create main catalog node
            const mainDocRef = doc(collectionRef);

            // Create default poll options
            const defaultPollOptions = ["New Button"];
            const pollItemIds = [];

            // Create poll nodes
            for (const pollText of defaultPollOptions) {
                const pollDocRef = doc(collectionRef);
                const pollNodeId = pollDocRef.id;

                await setDoc(pollDocRef, {
                    id: pollNodeId,
                    blockId: pollNodeId,
                    name: pollText,
                    createdBy: user.id,
                    status: "active",
                    flowId: flow.id,
                    parentNode: mainDocRef.id,
                    position: { x: 20, y: 460 },
                    extent: "parent",
                    type: "PollNode",
                    date: new Date(),
                    updatedDate: new Date(),
                    draggable: false,
                    reactFlowStatus: true,
                    blockType: "PollNode",
                });

                pollItemIds.push(pollNodeId);
            }

            // Create main catalog node with poll data
            await setDoc(mainDocRef, {
                id: mainDocRef.id,
                blockId: mainDocRef.id,
                name: "Meta Catalog",
                createdBy: user.id,
                status: "active",
                flowId: flow.id,
                count: 0,
                time: 0,
                timeUnit: "minutes",
                orderId: tempOrderId,
                message: "",
                title: "",
                subTitle: "",
                position: {
                    x: viewPortCenter.x,
                    y: viewPortCenter.y,
                },
                updatedDate: new Date(),
                metaCatalog: true,
                blockType: "sequential",
                date: new Date(),
                type: "BlockNode",
                functionItems: [],
                arrPollItems: pollItemIds,
                pollObject: {
                    pollName: "",
                    pollOptions: defaultPollOptions,
                },
                reactFlowStatus: true,
                style: {
                    style: {
                        width: 300,
                        height: 400,
                        zIndex: 0,
                    },
                    zIndex: 0,
                },
            });

            // Create the React Flow node data
            const mainNodeData = {
                id: mainDocRef.id,
                blockId: mainDocRef.id,
                type: "BlockNode",
                metaCatalog: true,
                title: "",
                subTitle: "",
                position: {
                    x: viewPortCenter.x,
                    y: viewPortCenter.y,
                },
                style: {
                    zIndex: 0,
                    width: 300,
                    height: 400,
                },
                data: {
                    metaCatalog: true,
                    name: "Meta Catalog",
                    title: "",
                    subTitle: "",
                    id: mainDocRef.id,
                    blockId: mainDocRef.id,
                    createdBy: user.id,
                    status: "active",
                    flowId: flow.id,
                    count: 0,
                    time: 0,
                    timeUnit: "minutes",
                    orderId: tempOrderId,
                    message: "",
                    updatedDate: new Date(),
                    blockType: "sequential",
                    date: new Date(),
                    functionItems: [],
                    arrPollItems: pollItemIds,
                    pollObject: {
                        pollName: "",
                        pollOptions: defaultPollOptions,
                    },
                    reactFlowStatus: true,
                    style: {
                        zIndex: 0,
                        width: 300,
                        height: 400,
                    },
                },
            };

            // Create poll nodes data for React Flow
            const pollNodesData = pollItemIds.map((id, index) => ({
                id: id,
                type: "PollNode",
                position: { x: 2, y: 460 },
                data: {
                    name: defaultPollOptions[index],
                    id: id,
                },
                parentNode: mainDocRef.id,
                extent: "parent",
                draggable: false,
                style: { zIndex: 1 },
            }));

            // Add all nodes at once
            setNodes((nds) => [...nds, mainNodeData, ...pollNodesData]);
        } catch (err) {
            console.log("ReactFlowPage.jsx:", err);
        } finally {
            setBlockMenu(null);
        }
    };

    // const handleCreateLabelNode = async () => {
    //     console.log("handleCreateLabelNode");
    //     try {
    //         if (!reactFlowInstance) {
    //             console.error("React Flow instance is not available");
    //             return;
    //         }
    //         //set the initial location of new node. this have to run first cause need to save into new node database.
    //         const viewPortCenter = reactFlowInstance.screenToFlowPosition({
    //             x: window.innerWidth / 2,
    //             y: window.innerHeight / 2,
    //         });

    //         const collectionRef = collection(db, "users", user.id, collectionName, flow.id, "blocks");
    //         // const q = query(collectionRef, where("blockType", "==", "labelNode"));
    //         // //to check if inside blocks there is no record.this part is to cater system migrating where previous system is using orderId for sequencing and 0 means that the block is the first item in the flow.
    //         // const snapshot = await getDocs(q);

    //         let tempOrderId = 999;
    //         ///need to add checker where if this is the first node, set orderId = 0
    //         const docRef = doc(collectionRef);

    //         await setDoc(
    //             docRef,
    //             {
    //                 id: docRef.id,
    //                 blockId: docRef.id, // Save the document ID
    //                 name: "labelNode",
    //                 createdBy: user.id,
    //                 status: "inactive",
    //                 flowId: flow.id,
    //                 // count: 0,
    //                 // time: 0,
    //                 // timeUnit: "minutes",
    //                 orderId: tempOrderId,
    //                 // message: "",
    //                 position: {
    //                     x: viewPortCenter.x,
    //                     y: viewPortCenter.y,
    //                 },
    //                 updatedDate: new Date(),
    //                 date: new Date(),
    //                 type: "BlockNode",
    //                 blockType: "labelNode",
    //                 // functionItems: [],
    //                 // arrPollItems: [],
    //                 reactFlowStatus: true,
    //             },
    //             { merge: true }
    //         );

    //         const data = {
    //             id: docRef.id,
    //             blockId: docRef.id,
    //             type: "BlockNode",
    //             position: {
    //                 x: viewPortCenter.x,
    //                 y: viewPortCenter.y,
    //             },
    //             data: {
    //                 name: "labelNode",
    //                 type: "BlockNode",
    //                 id: docRef.id,
    //                 blockId: docRef.id,
    //                 createdBy: user.id,
    //                 status: "inactive",
    //                 flowId: flow.id,
    //                 count: 0,
    //                 time: 0,
    //                 timeUnit: "minutes",
    //                 orderId: tempOrderId,
    //                 message: "",
    //                 updatedDate: new Date(),
    //                 blockType: "labelNode",
    //                 date: new Date(),
    //                 functionItems: [],
    //                 arrPollItems: [],
    //                 reactFlowStatus: true,
    //             },
    //         };

    //         console.log("handleCreateNode x:", viewPortCenter.x);
    //         console.log("handleCreateNode y:", viewPortCenter.y);

    //         setNodes((nds) => [...nds, data]);
    //         // setRerender(!rerender);
    //     } catch (err) {
    //         console.log("ReactFlowPage.jsx:", err);
    //     } finally {
    //         setBlockMenu(null);
    //     }
    // };

    const handleCreateLabelNode = async () => {
        try {
            if (!reactFlowInstance) {
                console.error("React Flow instance is not available");
                return;
            }
            //set the initial location of new node. this have to run first cause need to save into new node database.
            const viewPortCenter = reactFlowInstance.screenToFlowPosition({
                x: window.innerWidth / 2,
                y: window.innerHeight / 2,
            });

            const collectionRef = collection(db, "users", user.id, collectionName, flow.id, "blocks");

            //to check if inside blocks there is no record.this part is to cater system migrating where previous system is using orderId for sequencing and 0 means that the block is the first item in the flow.
            const snapshot = await getDocs(collectionRef);

            let tempOrderId = snapshot.empty ? 0 : 999;
            ///need to add checker where if this is the first node, set orderId = 0
            const docRef = doc(collectionRef);

            await setDoc(
                docRef,
                {
                    id: docRef.id,
                    blockId: docRef.id, // Save the document ID
                    name: "labelNode",
                    createdBy: user.id,
                    status: "inactive",
                    flowId: flow.id,
                    count: 0,
                    time: 0,
                    timeUnit: "minutes",
                    orderId: tempOrderId,
                    message: "",
                    position: {
                        x: viewPortCenter.x,
                        y: viewPortCenter.y,
                    },
                    updatedDate: new Date(),
                    date: new Date(),
                    type: "BlockNode",
                    blockType: "labelNode",
                    functionItems: [],
                    arrPollItems: [],
                    reactFlowStatus: true,
                },
                { merge: true }
            );

            const data = {
                id: docRef.id,
                blockId: docRef.id,
                type: "BlockNode",
                position: {
                    x: viewPortCenter.x,
                    y: viewPortCenter.y,
                },
                data: {
                    name: "labelNode",
                    id: docRef.id,
                    blockId: docRef.id,
                    createdBy: user.id,
                    status: "inactive",
                    flowId: flow.id,
                    count: 0,
                    time: 0,
                    timeUnit: "minutes",
                    orderId: tempOrderId,
                    message: "",
                    updatedDate: new Date(),
                    blockType: "labelNode",
                    date: new Date(),
                    functionItems: [],
                    arrPollItems: [],
                    reactFlowStatus: true,
                },
            };

            console.log("handleCreatelabelNode x:", viewPortCenter.x);
            console.log("handleCreatelabelNode y:", viewPortCenter.y);

            setNodes((nds) => [...nds, data]);
            // setRerender(!rerender);
        } catch (err) {
            console.log("ReactFlowPage.jsx:", err);
        } finally {
            setBlockMenu(null);
        }
    };

    const handleCreateStopFlowNode = async () => {
        try {
            if (!reactFlowInstance) {
                console.error("React Flow instance is not available");
                return;
            }
            //set the initial location of new node. this have to run first cause need to save into new node database.
            const viewPortCenter = reactFlowInstance.screenToFlowPosition({
                x: window.innerWidth / 2,
                y: window.innerHeight / 2,
            });

            const collectionRef = collection(db, "users", user.id, collectionName, flow.id, "blocks");

            //to check if inside blocks there is no record.this part is to cater system migrating where previous system is using orderId for sequencing and 0 means that the block is the first item in the flow.
            const snapshot = await getDocs(collectionRef);

            let tempOrderId = snapshot.empty ? 0 : 999;
            ///need to add checker where if this is the first node, set orderId = 0
            const docRef = doc(collectionRef);

            await setDoc(
                docRef,
                {
                    id: docRef.id,
                    blockId: docRef.id, // Save the document ID
                    name: "stopFlowNode",
                    createdBy: user.id,
                    status: "inactive",
                    flowId: flow.id,
                    count: 0,
                    time: 0,
                    timeUnit: "minutes",
                    orderId: tempOrderId,
                    message: "",
                    position: {
                        x: viewPortCenter.x,
                        y: viewPortCenter.y,
                    },
                    updatedDate: new Date(),
                    date: new Date(),
                    type: "BlockNode",
                    blockType: "stopFlowNode",
                    functionItems: [],
                    arrPollItems: [],
                    reactFlowStatus: true,
                },
                { merge: true }
            );

            const data = {
                id: docRef.id,
                blockId: docRef.id,
                type: "BlockNode",
                position: {
                    x: viewPortCenter.x,
                    y: viewPortCenter.y,
                },
                data: {
                    name: "stopFlowNode",
                    id: docRef.id,
                    blockId: docRef.id,
                    createdBy: user.id,
                    status: "inactive",
                    flowId: flow.id,
                    count: 0,
                    time: 0,
                    timeUnit: "minutes",
                    orderId: tempOrderId,
                    message: "",
                    updatedDate: new Date(),
                    blockType: "stopFlowNode",
                    date: new Date(),
                    functionItems: [],
                    arrPollItems: [],
                    reactFlowStatus: true,
                },
            };

            console.log("handleCreateStopFlowNode x:", viewPortCenter.x);
            console.log("handleCreateStopFlowNode y:", viewPortCenter.y);

            setNodes((nds) => [...nds, data]);
            // setRerender(!rerender);
        } catch (err) {
            console.log("ReactFlowPage.jsx:", err);
        } finally {
            setBlockMenu(null);
        }
    };

    const handleCreateWhatsappFlowNode = async () => {
        // when create, all name to set as Node Name. after that user will need to 1 by 1 reenter the name for the node.
        try {
            if (!reactFlowInstance) {
                console.error("React Flow instance is not available");
                return;
            }
            //set the initial location of new node. this have to run first cause need to save into new node database.
            const viewPortCenter = reactFlowInstance.screenToFlowPosition({
                x: window.innerWidth / 2,
                y: window.innerHeight / 2,
            });

            const collectionRef = collection(db, "users", user.id, collectionName, flow.id, "blocks");

            //to check if inside blocks there is no record.this part is to cater system migrating where previous system is using orderId for sequencing and 0 means that the block is the first item in the flow.
            const snapshot = await getDocs(collectionRef);

            let tempOrderId = snapshot.empty ? 0 : 999;
            ///need to add checker where if this is the first node, set orderId = 0
            const docRef = doc(collectionRef);

            await setDoc(
                docRef,
                {
                    id: docRef.id,
                    blockId: docRef.id, // Save the document ID
                    name: "WhatsappFlowNode",
                    createdBy: user.id,
                    status: "inactive",
                    flowId: flow.id,
                    count: 0,
                    time: 0,
                    timeUnit: "minutes",
                    orderId: tempOrderId,
                    message: "",
                    position: {
                        x: viewPortCenter.x,
                        y: viewPortCenter.y,
                    },
                    updatedDate: new Date(),
                    date: new Date(),
                    type: "BlockNode",
                    blockType: "whatsappFlow",
                    functionItems: [],
                    arrPollItems: [],
                    reactFlowStatus: true,
                },
                { merge: true }
            );

            const data = {
                id: docRef.id,
                blockId: docRef.id,
                type: "BlockNode",
                position: {
                    x: viewPortCenter.x,
                    y: viewPortCenter.y,
                },
                data: {
                    name: "New Node Name",
                    id: docRef.id,
                    blockId: docRef.id,
                    createdBy: user.id,
                    status: "inactive",
                    flowId: flow.id,
                    count: 0,
                    time: 0,
                    timeUnit: "minutes",
                    orderId: tempOrderId,
                    message: "",
                    updatedDate: new Date(),
                    blockType: "whatsappFlow",
                    date: new Date(),
                    functionItems: [],
                    arrPollItems: [],
                    reactFlowStatus: true,
                },
            };

            console.log("handleCreateNode x:", viewPortCenter.x);
            console.log("handleCreateNode y:", viewPortCenter.y);

            setNodes((nds) => [...nds, data]);
            // setRerender(!rerender);
        } catch (err) {
            console.log("ReactFlowPage.jsx:", err);
        } finally {
            setBlockMenu(null);
        }
    };

    const handleCreateAINode = async (type) => {
        try {
            if (!reactFlowInstance) {
                console.error("React Flow instance is not available");
                return;
            }
            //set the initial location of new node. this have to run first cause need to save into new node database.
            const viewPortCenter = reactFlowInstance.screenToFlowPosition({
                x: window.innerWidth / 2,
                y: window.innerHeight / 2,
            });

            const collectionRef = collection(db, "users", user.id, collectionName, flow.id, "blocks");
            if (type === "trigger") {
                // check existing trigger node
                const q = query(collectionRef, where("blockType", "==", "trigger"));
                const snapshot = await getDocs(q);
                if (!snapshot.empty) {
                    toast.error("Trigger node already exists. Only one trigger node is allowed.");
                    return;
                }
            }

            const docRef = doc(collectionRef);
            await setDoc(
                docRef,
                {
                    id: docRef.id,
                    blockId: docRef.id, // Save the document ID
                    name: "New AI Node Name",
                    createdBy: user.id,
                    status: "active",
                    flowId: flow.id,
                    count: 0,
                    orderId: 999,
                    blockType: type,
                    time: 0,
                    timeUnit: "minutes",
                    message: "",
                    position: {
                        x: viewPortCenter.x,
                        y: viewPortCenter.y,
                    },
                    updatedDate: new Date(),
                    date: new Date(),
                    type: "BlockNode",
                    functionItems: [],
                    arrPollItems: [],
                    reactFlowStatus: true,
                },
                { merge: true }
            );

            const data = {
                id: docRef.id,
                blockId: docRef.id,
                type: "BlockNode",
                position: {
                    x: viewPortCenter.x,
                    y: viewPortCenter.y,
                },
                data: {
                    name: "New AI Node Name",
                    id: docRef.id,
                    blockId: docRef.id,
                    createdBy: user.id,
                    status: "active",
                    flowId: flow.id,
                    orderId: 999,
                    count: 0,
                    time: 0,
                    timeUnit: "minutes",
                    message: "",
                    updatedDate: new Date(),
                    blockType: type,
                    date: new Date(),
                    functionItems: [],
                    arrPollItems: [],
                    reactFlowStatus: true,
                },
            };

            console.log("handleCreateNode x:", viewPortCenter.x);
            console.log("handleCreateNode y:", viewPortCenter.y);

            setNodes((nds) => [...nds, data]);
            setAnchorEl(null);
        } catch (err) {
            console.log("ReactFlowPage.jsx:", err);
            setAnchorEl(null);
        }
    };

    const handleCreateIntentionNode = async (type) => {
        try {
            if (!reactFlowInstance) {
                console.error("React Flow instance is not available");
                return;
            }
            //set the initial location of new node. this have to run first cause need to save into new node database.
            const viewPortCenter = reactFlowInstance.screenToFlowPosition({
                x: window.innerWidth / 2,
                y: window.innerHeight / 2,
            });

            const collectionRef = collection(db, "users", user.id, collectionName, flow.id, "blocks");

            const docRef = doc(collectionRef);
            await setDoc(
                docRef,
                {
                    id: docRef.id,
                    blockId: docRef.id, // Save the document ID
                    name: "New AI Node Name",
                    createdBy: user.id,
                    status: "active",
                    flowId: flow.id,
                    count: 0,
                    orderId: 999,
                    blockType: type,
                    time: 0,
                    timeUnit: "minutes",
                    message: "",
                    position: {
                        x: viewPortCenter.x,
                        y: viewPortCenter.y,
                    },
                    updatedDate: new Date(),
                    date: new Date(),
                    type: "BlockNode",
                    functionItems: [],
                    arrPollItems: [],
                    reactFlowStatus: true,
                },
                { merge: true }
            );

            const data = {
                id: docRef.id,
                blockId: docRef.id,
                type: "BlockNode",
                position: {
                    x: viewPortCenter.x,
                    y: viewPortCenter.y,
                },

                data: {
                    name: "New AI Node Name",
                    id: docRef.id,
                    blockId: docRef.id,
                    createdBy: user.id,
                    status: "active",
                    flowId: flow.id,
                    orderId: 999,
                    count: 0,
                    time: 0,
                    timeUnit: "minutes",
                    message: "",
                    updatedDate: new Date(),
                    blockType: type,
                    date: new Date(),
                    functionItems: [],
                    arrPollItems: [],
                    reactFlowStatus: true,
                    onOpenDrawer: openDrawerFunction,
                },
            };

            console.log("handleCreateNode x:", viewPortCenter.x);
            console.log("handleCreateNode y:", viewPortCenter.y);

            setNodes((nds) => [...nds, data]);
            setAnchorEl(null);
        } catch (err) {
            console.log("ReactFlowPage.jsx:", err);
            setAnchorEl(null);
        }
    };

    function toDate(firestoreTimestamp) {
        if (
            !firestoreTimestamp ||
            typeof firestoreTimestamp.seconds !== "number" ||
            typeof firestoreTimestamp.nanoseconds !== "number"
        ) {
            // Return a default date (e.g., the current date) if the timestamp is invalid
            // Alternatively, you could return null and handle it in the sorting logic
            return new Date();
        }
        return new Date(firestoreTimestamp.seconds * 1000 + firestoreTimestamp.nanoseconds / 1000000);
    }

    const fetchNodes = async () => {
        if (!reactFlowInstance) {
            console.error("React Flow instance is not available.");
            return;
        }

        //set the initial location of new node. this have to run first cause need to save into new node database.
        const viewPortCenter = reactFlowInstance.screenToFlowPosition({
            x: window.innerWidth / 2,
            y: window.innerHeight / 2,
        });

        // console.log("fetchNodes x:", viewPortCenter.x);
        // console.log("fetchNodes y:", viewPortCenter.y);

        const collectionRef = collection(db, "users", user.id, collectionName, flow.id, "blocks");

        try {
            const snapshot = await getDocs(collectionRef);

            let increment = -15;
            if (!snapshot.empty) {
                let allBlocks = mapSnapshot(snapshot).map((item) => {
                    //add extra field for block from previous system
                    if (item.reactFlowStatus === undefined) {
                        increment += 15;

                        //update block that is from previous system.
                        let itemObj = {
                            type: "BlockNode",
                            blockType: "sequential",
                            position: {
                                x: viewPortCenter.x + increment,
                                y: viewPortCenter.y + increment,
                            },
                            reactFlowStatus: true,
                        };

                        updateBlock(user, flow, item.id, itemObj);

                        //**********need to update all existing edge to react flow system*******//

                        return {
                            ...item,
                            data: {
                                ...item,
                                type: "BlockNode",
                                blockType: "sequential",
                                position: {
                                    x: viewPortCenter.x + increment,
                                    y: viewPortCenter.y + increment,
                                },
                                reactFlowStatus: true,
                            }, //customize node format. data need to pass into a field name 'data'
                            type: "BlockNode",
                            blockType: "sequential",
                            position: {
                                x: viewPortCenter.x + increment,
                                y: viewPortCenter.y + increment,
                            },
                            reactFlowStatus: true,
                        };
                    } else {
                        return {
                            ...item,
                            position: {
                                x: item.position.x - 17.5, // Add 10 to the existing x value
                                y: item.position.y, // Add 10 to the existing y value
                            },
                            data: {
                                ...item,
                                position: {
                                    x: item.position.x - 17.5, // Add 10 to the existing x value
                                    y: item.position.y, // Add 10 to the existing y value
                                },
                                onOpenDrawer: item.blockType === "intention" ? openDrawerFunction : undefined,
                            }, // Customize node format. data need to pass into a field name 'data'
                        };
                    }
                });

                allBlocks.sort((a, b) => {
                    const dateA = toDate(a.updatedDate); // Use updatedDate instead of createdDate
                    const dateB = toDate(b.updatedDate);

                    // Assuming you still want to prioritize orderId === 0 at the start
                    if (a.orderId === 0) return -1;
                    if (b.orderId === 0) return 1;

                    // If different types, and you still want BlockNode before PollNode
                    if (a.type !== b.type) {
                        return a.type === "BlockNode" ? -1 : 1;
                    }

                    // Since you want recently updated items to appear at the end, sort by updatedDate ascending
                    return dateA - dateB; // For ascending, swap dateA and dateB for descending
                });

                setFirstNode(allBlocks[0].blockId);

                setNodes([...allBlocks]);

                setLoading(false);
            } else {
                console.log("There is 0 block found.");
                setNodes([]);
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching nodes:", error);
        }
    };

    const fetchEdge = async () => {
        //
        const allEdges = await readBlockRelation(user, flow);

        if (!allEdges || allEdges.length === 0) {
            //
            await updateAllSequence999(user, flow);

            console.log("No records, or allEdges is null or empty");

            // Handle the case when allEdges is null, empty or has no records
            return;
        }

        let loadEdges = allEdges.map((edge) => {
            return {
                id: edge.id,
                source: edge.sourceNodeId,
                target: edge.targetNodeId,
                sourceHandle: edge.sourceHandleId,
                type: edge.type || "custom-edge",
                ...edgeLabelStyle,
            };
        });
        // console.log("loadEdges:", loadEdges);
        setEdges(loadEdges);
    };

    const edgeUpdateSuccessful = useRef(true);

    const onEdgeUpdateStart = useCallback(() => {
        edgeUpdateSuccessful.current = false;
    }, []);

    const onEdgeUpdate = useCallback(async (oldEdge, newConnection) => {
        console.log("onEdgeUpdate");
        edgeUpdateSuccessful.current = true;

        let relationObj = {
            sourceNodeId: oldEdge.source,
            targetNodeId: oldEdge.target,
        };

        //add relation from new node
        let newRelationFromNewNodeObj = {
            sourceNodeId: newConnection.target,
            targetNodeId: oldEdge.target,
            sourceHandleId: oldEdge.sourceHandle,
            flowId: flow.id,
        };

        //add relation to new node
        let newRelationToNodeObj = {
            sourceNodeId: oldEdge.source,
            targetNodeId: newConnection.target,
            sourceHandleId: oldEdge.sourceHandle,
            flowId: flow.id,
        };

        // Check the conditions
        if (
            newRelationFromNewNodeObj.sourceNodeId === newRelationFromNewNodeObj.targetNodeId ||
            newRelationToNodeObj.sourceNodeId === newRelationToNodeObj.targetNodeId
        ) {
            console.log("Warning: Skipping addBlockRelation due to sourceNodeId being equal to targetNodeId");
        } else {
            try {
                await removeBlockRelation(user, flow, relationObj);
                await addBlockRelation(user, flow, newRelationFromNewNodeObj);
                await addBlockRelation(user, flow, newRelationToNodeObj);
            } catch (error) {
                console.error("Error updating block relations:", error);
            }
        }

        //update the sequences of remaining nodes
        // updateBlockSequence(user, flow, firstNode);
        await fetchEdge();
        // setEdges((els) => updateEdge(oldEdge, newConnection, els));
        // setRefresh(!refresh);
    }, []);

    const onEdgeUpdateEnd = useCallback(
        (_, edge) => {
            if (!edgeUpdateSuccessful.current) {
                console.log("onEdgeUpdateEnd");
                setEdges((eds) => eds.filter((e) => e.id !== edge.id));

                let relationObj = {
                    sourceNodeId: edge.source,
                    targetNodeId: edge.target,
                };
                //remove relation in firebase
                removeBlockRelation(user, flow, relationObj);
                // updateBlockSequence(user, flow, firstNode);
            }
            // edgeUpdateSuccessful.current = true;
        },
        [setEdges]
    );

    const handleEdgeDelete = useCallback(
        async (edgeId) => {
            // Remove edge from React state
            setEdges((currentEdges) => currentEdges.filter((e) => e.id !== edgeId));
            console.log("edgeId:", edgeId);

            try {
                const edgeData = await readBlockRelationById(user, flow, edgeId);
                if (!edgeData) {
                    console.error("No edge data found for the given edge ID:", edgeId);
                    return;
                }

                // Remove the edge from Firebase
                await removeBlockRelationById(user, flow, edgeId);
                console.log("Edge removed from Firebase");

                const { sourceNodeId, targetNodeId } = edgeData;
                console.log("Edge Data:", edgeData);

                const sourceBlock = await readBlock(user, flow, sourceNodeId);
                console.log(sourceBlock);

                if (!sourceBlock || !Array.isArray(sourceBlock.targetNodeId)) {
                    console.error("Source block not found or it doesn't have a targetNodeId array.");
                    return;
                }

                // Filter out the targetNodeId to remove it
                const updatedTargetNodeIds = sourceBlock.targetNodeId.filter((id) => id !== targetNodeId);

                // Update the source block with the new array of targetNodeIds
                await updateBlock(user, flow, sourceNodeId, { targetNodeId: updatedTargetNodeIds });
                console.log(`Removed targetNodeId: ${targetNodeId} from sourceBlock: ${sourceNodeId}`);

                // Optionally, trigger any refresh or additional state updates needed
                setRefresh((prev) => !prev);
            } catch (error) {
                console.error("Error processing edge deletion:", error);
            }
        },
        [setEdges, user, flow, setRefresh]
    );

    const edgeTypes = useMemo(
        () => ({
            "custom-edge": (props) => (
                <CustomEdge
                    {...props}
                    onDelete={handleEdgeDelete}
                    style={{ zIndex: edgeVisible ? 10001 : 0 }} // Always one level above the edge when visible
                    visible={edgeVisible} // Pass visibility state to CustomEdge
                />
            ),
        }),
        [handleEdgeDelete, edgeVisible] // Add edgeVisible to dependencies
    );

    const updateNodePositionInFirestore = async (lastNodePos) => {
        try {
            const nodeRef = doc(db, "users", user.id, collectionName, flow.id, "blocks", lastNodePos.id);

            // Check if the document exists
            const docSnap = await getDoc(nodeRef);

            if (docSnap.exists()) {
                await updateDoc(nodeRef, {
                    position: {
                        x: lastNodePos.x,
                        y: lastNodePos.y,
                    },
                    updatedDate: new Date(),
                });

                // Update the position in the local React state
                setNodes((currentNodes) => {
                    return currentNodes.map((node) => {
                        if (node.id === lastNodePos.id) {
                            return { ...node, position: { x: lastNodePos.x, y: lastNodePos.y } };
                        }
                        return node;
                    });
                });
            } else {
                console.log(`Node with ID ${lastNodePos.id} does not exist. Skipping update.`);
            }
        } catch (error) {
            console.error("Error updating node position in Firestore:", error);
        }
    };

    let lastNodePos;

    const modifiedOnNodesChange = useCallback(
        (changes) => {
            setNodes((nds) => applyNodeChanges(changes, nds));

            changes.forEach((change) => {
                if (change.type === "position" && change.dragging === true) {
                    // This is where the node was dropped

                    lastNodePos = change.position;
                    lastNodePos = { ...lastNodePos, id: change.id };
                } else {
                    if (lastNodePos) {
                        updateNodePositionInFirestore(lastNodePos);
                    }
                }
            });
        },
        [setNodes] // No need to include onNodesChange in dependencies
    );

    useEffect(() => {
        if (!flow.id || !user.id) return;

        const fetchData = async () => {
            try {
                setLoading(true);
                await Promise.all([fetchNodes(), fetchEdge()]);
                console.log("Nodes and edges refreshed. First node:", firstNode);
            } catch (err) {
                console.error("Error fetching data:", err);
            } finally {
                setIsDeleting(false);
                setLoading(false);
            }
        };

        fetchData();
    }, [flow, updateTrigger, refresh]);

    const proOptions = { hideAttribution: true };

    return (
        <>
            <div style={{ width: "100vw", height: "85vh", position: "relative" }}>
                {!loading && (
                    <>
                        <Box sx={{ position: "absolute", top: 10, right: 20 }}>
                            <Tooltip title="Add a new node" placement="top">
                                <Fab color="primary" onClick={(e) => setBlockMenu(e.currentTarget)}>
                                    <AddCircleOutlineIcon fontSize="large" />
                                </Fab>
                            </Tooltip>
                            <Menu
                                anchorEl={blockMenu}
                                open={Boolean(blockMenu)}
                                onClose={() => setBlockMenu(null)}
                                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                                transformOrigin={{ vertical: "top", horizontal: "right" }}
                            >
                                <MenuItem onClick={handleCreateNode}>Block Node</MenuItem>
                                <MenuItem onClick={handleCreateWhatsappFlowNode}>Whatsapp Flow Node</MenuItem>
                                <MenuItem onClick={handleCreateLabelNode}>Label Node</MenuItem>
                                <MenuItem onClick={handleCreateStopFlowNode}>Stop Flow Node</MenuItem>
                                <MenuItem onClick={handleCreateMetaCatalogNode}>
                                    Meta Catalog (only for <FacebookIcon sx={{ ml: 1 }} fontSize="small" />
                                    <InstagramIcon sx={{ ml: 0.5 }} fontSize="small" />)
                                </MenuItem>
                            </Menu>
                        </Box>
                        <Box sx={{ position: "absolute", top: 70, right: 20 }}>
                            <Tooltip title="Add a new AI Node" placement="top">
                                <Fab color="error" onClick={(e) => setAnchorEl(e.currentTarget)}>
                                    <AddCircleOutlineIcon fontSize="large" />
                                </Fab>
                            </Tooltip>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={() => setAnchorEl(null)}
                                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                                transformOrigin={{ vertical: "top", horizontal: "right" }}
                            >
                                <MenuItem onClick={() => handleCreateAINode("trigger")}>
                                    AI Trigger Node
                                </MenuItem>
                                <MenuItem onClick={() => handleCreateAINode("action")}>
                                    AI Action Node
                                </MenuItem>
                                {user.role === "Super Admin" && (
                                    <MenuItem onClick={() => handleCreateIntentionNode("intention")}>
                                        AI Intention Node
                                    </MenuItem>
                                )}
                            </Menu>
                        </Box>
                        <Box sx={{ position: "absolute", top: 130, right: 20 }}>
                            <Tooltip
                                title={edgeVisible ? "Send Edges To Back" : "Bring Edges To Front"}
                                placement="top"
                            >
                                <Fab color="secondary" onClick={handleVisibilityToggle}>
                                    {edgeVisible ? (
                                        <VisibilityIcon fontSize="large" />
                                    ) : (
                                        <VisibilityOffIcon fontSize="large" />
                                    )}
                                </Fab>
                            </Tooltip>
                        </Box>
                    </>
                )}

                {loading && (
                    <Box width={"100%"}>
                        <LinearProgress />
                    </Box>
                )}
                <ReactFlow
                    proOptions={proOptions} //to remove the 'reactflow' text in background
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={modifiedOnNodesChange} // Use the modified onNodesChange here
                    fitView
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    nodeTypes={nodeTypes}
                    edgeTypes={edgeTypes}
                    onEdgeUpdate={onEdgeUpdate}
                    onEdgeUpdateStart={onEdgeUpdateStart}
                    onEdgeUpdateEnd={onEdgeUpdateEnd}
                    minZoom={0.001} // Allows zooming out to 0.1% of the original size
                    maxZoom={3}
                >
                    <Controls />
                    <MiniMap zoomable pannable />
                    <Background variant="dots" gap={12} size={1} />
                </ReactFlow>
            </div>
            <Backdrop
                sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
                open={isDeleting || loading}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}

export default function OverviewFlowPage({ flow, openDrawerFunction }) {
    const [updateTrigger, setUpdateTrigger] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    return (
        <ReactFlowProvider>
            <FlowContext.Provider value={{ flow, setUpdateTrigger, isDeleting, setIsDeleting }}>
                <Flow
                    flow={flow}
                    updateTrigger={updateTrigger}
                    setUpdateTrigger={setUpdateTrigger}
                    isDeleting={isDeleting}
                    setIsDeleting={setIsDeleting}
                    openDrawerFunction={openDrawerFunction}
                />
            </FlowContext.Provider>
        </ReactFlowProvider>
    );
}
