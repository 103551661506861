import { Box, Button, Dialog, Icon, IconButton, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import ImageIndicator from "../../components/ChatInbox/ImageIndicator";
import UploadFilesButton from "../../components/UploadFile/UploadFilesButton";
import { useEffect } from "react";
import { extractRecordUrl, removeRecordUrl } from "./urlFunctions";

export default function RecordDialog({
    openAddFiles,
    setOpenAddFiles,
    files,
    handleAddFiles,
    handleDeleteFile,
    handleAddFilesRecord,
    record,
    title,
}) {
    const [input, setInput] = useState("");
    const [link, setLink] = useState("");

    useEffect(() => {
        if (!record) return;
        // Update files
        if (record.files) {
            const link = extractRecordUrl(record.input);
            if (link) {
                setLink(link);
                const input = removeRecordUrl(record.input);
                setInput(input);
            } else {
                setInput(record.input);
            }
        } else {
            setInput(record.input);
        }
        //
    }, [record]);

    return (
        <Dialog
            open={openAddFiles}
            onClose={() => setOpenAddFiles(false)}
            PaperProps={{ sx: { width: ["99vw", "60vw"], maxWidth: "none" } }}
        >
            <form onSubmit={handleAddFilesRecord}>
                <Box display="flex" flexDirection="column" p={3} gap={2}>
                    {record ? (
                        <Box display="flex" flexDirection={"column"} gap={2}>
                            <TextField
                                label="input"
                                fullWidth
                                name="input"
                                multiline
                                rows={6}
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                            />
                            {/* {Boolean(link) && <TextField label="link" fullWidth name="link" value={link} />} */}
                        </Box>
                    ) : (
                        <>
                            <Box>
                                <TextField
                                    label="Question"
                                    fullWidth
                                    name="question"
                                    defaultValue={`Can you send me the layout plans for ${title}?`}
                                />
                            </Box>
                            <Box>
                                <TextField
                                    label="Answer"
                                    fullWidth
                                    name="answer"
                                    defaultValue={"You can download the files via the link below:"}
                                />
                            </Box>
                        </>
                    )}

                    <Box
                        display="flex"
                        flexDirection={"column"}
                        alignItems={"flex-start"}
                        sx={{ maxHeight: "400px", overflowY: "auto" }}
                    >
                        {files?.map((file, index) => (
                            <Box
                                key={index}
                                display={"flex"}
                                alignItems={"center"}
                                gap={1}
                                justifyContent={"space-between"}
                                width={"400px"}
                            >
                                <ImageIndicator image={file} height={80} />
                                <Typography noWrap sx={{ wordBreak: "break-word" }}>
                                    {file?.name}
                                </Typography>
                                <IconButton onClick={() => handleDeleteFile(index)}>
                                    <Icon>delete</Icon>
                                </IconButton>
                            </Box>
                        ))}
                    </Box>
                    <UploadFilesButton handleFile={handleAddFiles} label="Add Files" />
                    <Box>
                        <Button
                            variant="contained"
                            color="warning"
                            size="large"
                            startIcon={<Icon>add</Icon>}
                            type="submit"
                        >
                            {record ? "Update Record" : "Add Record"}
                        </Button>
                    </Box>
                </Box>
            </form>
        </Dialog>
    );
}
