import { useGoogleLogin, CodeResponse } from "@react-oauth/google";
import { addDoc, collection, doc, onSnapshot } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";
import { Box, Button, CircularProgress, Container, Divider, Typography } from "@mui/material";
import { IGoogleAccountDocument } from "../../../functions/src/apis/Google/getGoogleToken";

export default function GoogleConnectPage() {
    const [docId, setDocId] = useState<string | undefined>(undefined);
    const [data, setData] = useState<IGoogleAccountDocument | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const { user } = useContext(AuthContext);

    useEffect(() => {
        if (!docId) return;

        const docRef = doc(db, "googleAccounts", docId);
        const unsubscribe = onSnapshot(docRef, (doc) => {
            if (doc.exists()) {
                const data = mapDocSnapshot(doc);
                setData(data);
                if (data.email) {
                    setLoading(false);
                }

                if (data.error) {
                    console.log(data.error);
                    setError(data.error);
                    setLoading(false);
                }
            } else {
                console.log("No such document!");
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, [docId]);

    const saveTempToken = async (codeResponse: CodeResponse) => {
        console.log(codeResponse);

        const docRef = collection(db, "googleAccounts");
        setLoading(true);
        const response = await addDoc(docRef, { userId: user.id, code: codeResponse.code, date: new Date() });
        setDocId(response.id);
        console.log("Saved Code");
    };

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => saveTempToken(codeResponse),
        flow: "auth-code",
        scope: "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/documents",
    });

    return (
        <Container sx={{ mt: 3 }}>
            <Box display="flex" flexDirection="column" gap={2}>
                <Box>
                    <Typography variant="h4">Connect Google</Typography>
                </Box>
                <Divider />
                {loading && <CircularProgress />}
                <Typography>{data?.email && `Google Account ${data.email} Authenticated`}</Typography>
                <Box display="flex" justifyContent="center">
                    <Button onClick={login} variant="contained" disabled={loading}>
                        Connect to Google
                    </Button>
                </Box>
                {error && (
                    <Box>
                        <Typography color="error">{error}</Typography>
                    </Box>
                )}
            </Box>
        </Container>
    );
}
