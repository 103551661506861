import { Box, Button, Dialog, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { IRow } from "./SalesOverviewPage";
import useList from "../../context-utils/ListContext";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import toast from "react-hot-toast";
import { AuthContext } from "../../context/AuthContext";

export default function AddCustomerDialog({
    openAddCustomerDialog,
    setOpenAddCustomerDialog,
    rows,
}: {
    openAddCustomerDialog: boolean;
    setOpenAddCustomerDialog: React.Dispatch<React.SetStateAction<boolean>>;
    rows: IRow[];
}) {
    const [customers, setCustomers] = React.useState<IRow[]>([]);
    const [referral, setReferral] = React.useState<IRow | null>(null);
    const [description, setDescription] = React.useState<string>("");

    const { user } = useContext(AuthContext);

    useEffect(() => {
        setCustomers(rows);
    }, [rows]);

    const list = useList();
    const handleSelectReferral = async () => {
        const response = await list(
            rows,
            "Select Referral",
            "displayName",
            "face",
            false,
            true,
            "email",
            false
        );
        if (response) {
            console.log(response);
            setReferral(response);
        }
    };

    const handleCreateCustomer = async () => {
        if (referral) {
            const newCustomer = {
                date: new Date(),
                description: description,
                referral: referral.id,
                uplineIds: referral.uplineIds,
                type: "manual",
                email: "",
                stripeId: "",
                stripeLink: "",
                createdBy: user.id,
            };

            const collectionRef = collection(db, "customers");
            await addDoc(collectionRef, newCustomer);
            toast.success("Customer added successfully");
            setOpenAddCustomerDialog(false);
        } else {
            toast.error("Please select a referral");
        }
    };

    return (
        <Dialog
            open={openAddCustomerDialog}
            onClose={() => setOpenAddCustomerDialog(false)}
            PaperProps={{ sx: { width: ["100vw", "40vw"] } }}
            maxWidth="lg"
        >
            <Box p={2} display="flex" flexDirection={"column"} gap={2}>
                <Typography>Add Customer</Typography>
                <Box display="flex" gap={1} alignItems={"center"} justifyContent={"flex-start"}>
                    <Typography sx={{ width: "150px" }}>Referral:</Typography>
                    {referral && <Typography>{referral.displayName}</Typography>}
                    <Button size="small" variant="contained" onClick={handleSelectReferral}>
                        Select Referral
                    </Button>
                </Box>
                <Box display="flex" gap={1} alignItems={"center"}>
                    <Box sx={{ width: "150px" }}>
                        <Typography>Description:</Typography>
                    </Box>
                    <Box flex={1}>
                        <TextField
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            size="small"
                            fullWidth
                        />
                    </Box>
                </Box>
                <Box display="flex" gap={1} alignItems={"center"}>
                    <Button variant="contained" onClick={handleCreateCustomer}>
                        Create
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
}
