import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Button, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { logEvent, setUserProperties } from "firebase/analytics";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { analytics } from "../firebase/firebase-utils";
import useVersionCheck from "../hooks/useVersionCheck";
import { HOMEPAGE_ITEMS } from "../settings/settings";
import { GridContainer, GridDivider, GridFlexBox, Name } from "../themes/themes";
import ExplosoftaiPage from "./ExplosoftaiPage/ExplosoftaiPage";

const STAGE = process.env.REACT_APP_STAGE;

export default function HomePage() {
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        logEvent(analytics, "user_checkin", {
            user_id: user.id,
            user_name: user.displayName,
            user_email: user.email,
            timestamp: new Date().toISOString(),
        });

        setUserProperties(analytics, {
            user_id: user.id,
            user_email: user.email,
            user_name: user.displayName,
            last_login: new Date().toISOString(),
        });
    }, [user]);

    const enabledItem = (item) => {
        if (item.addOn === "whatsappCloud" && user.addOn !== "whatsappCloud") return false;
        return true;
    };

    useVersionCheck();

    const displayOrNot = (item) => {
        let display = true;

        if (user.role === "Super Admin") return true;

        if (item.role) {
            if (user.role !== item.role) display = false;
        }
        if (item.hide) {
            if (item.hide === user.role) display = false;
        }

        return display;
    };

    const hostname = window.location.hostname;
    if (hostname === "explosoftai.com") {
        return <ExplosoftaiPage />;
    }

    return (
        <Box m={1}>
            <GridContainer>
                {STAGE === "stage" && (
                    <Box>
                        <Typography>Staging Account</Typography>
                    </Box>
                )}
                <GridFlexBox mtb2>
                    <Typography variant="h5">{user ? `Welcome ${user.displayName}` : "Homepage"}</Typography>
                </GridFlexBox>
                <GridDivider />
                {HOMEPAGE_ITEMS.map((item, i) => {
                    if (displayOrNot(item)) {
                        if (item.description === "title") {
                            return (
                                <GridFlexBox key={i}>
                                    <Name fs14>{item.title}</Name>
                                </GridFlexBox>
                            );
                        }

                        if (item.description === "divider") {
                            return <GridDivider key={i} />;
                        }

                        return (
                            <React.Fragment key={i}>
                                <GridFlexBox>
                                    <Button
                                        variant="contained"
                                        sx={{ backgroundColor: item.color, width: "300px", margin: "4px" }}
                                        onClick={() => navigate(item.link)}
                                        disabled={!enabledItem(item)}
                                    >
                                        {item.title}
                                    </Button>
                                    {!enabledItem(item) && (
                                        <Tooltip title={item.toolTip}>
                                            <InfoOutlinedIcon />
                                        </Tooltip>
                                    )}
                                </GridFlexBox>
                            </React.Fragment>
                        );
                    }
                })}
            </GridContainer>
        </Box>
    );
}
