import { Box, Paper, Switch, TextField, Typography, Select, MenuItem } from "@mui/material";
import { collection, doc, onSnapshot, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";
import React, { useContext, useEffect,useState } from "react";
import toast from "react-hot-toast";
import { IIntentionBlock } from "../../../functions/src/types/intentionType";
import { UserType } from "../../../functions/src/types/userTypes";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import mapDocSnapshotTS from "../../utils-functions/mapDocSnapshotTS";
import { IIntentionType } from "../../../functions/src/types/intentionType";
import mapSnapshot from "../../utils-functions/mapSnapshot";

export default function SubItemDetailsComponent({
    currentSubItem,
    currentItemId,
}: {
    currentSubItem: IIntentionBlock;
    currentItemId: string;
}) {
    const intentionTypes: IIntentionType[] = ["message", "grabData", "knowledge", "calculation"];

    const [newSubItem, setNewSubItem] = React.useState<IIntentionBlock | null>(currentSubItem);

    const { user } = useContext(AuthContext) as { user: UserType };
    const [catalogs, setCatalogs] = useState([]);

    useEffect(() => {
        if (!user) return;

        const collectionRef = collection(db, "catalogs");
        const q = query(
            collectionRef,
            where("admins", "array-contains", user.id),
            where("flowId", "==", currentItemId),
            orderBy("date", "desc")
        );
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const records = mapSnapshot(snapshot);
            console.log("Catalogs", records);
            setCatalogs(records);
        });

        return unsubscribe;
    }, [user]);

    useEffect(() => {
        if (currentSubItem) {
            const docRef = doc(
                db,
                "users",
                user.id,
                "whatsappFlows",
                currentItemId,
                "blocks",
                currentSubItem.id
            );
            const unsubscribe = onSnapshot(docRef, (doc: any) => {
                const intention = mapDocSnapshotTS<IIntentionBlock>(doc);
                setNewSubItem(intention);
                console.log("Intention", intention);
            });

            return unsubscribe;
        } else {
            setNewSubItem(null);
        }
    }, [currentSubItem]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
        if (!newSubItem) return;
        setNewSubItem({ ...newSubItem, [key]: e.target.value });
    };

    const handleSave = async () => {
        if (!newSubItem) return;

        if (JSON.stringify(newSubItem) === JSON.stringify(currentSubItem)) return;

        console.log("Save", newSubItem);
        const docRef = doc(db, "users", user.id, "whatsappFlows", currentItemId, "blocks", newSubItem.id);

        await setDoc(docRef, newSubItem, { merge: true });
        toast.success("Saved");
    };

    const handleChangeSwith = async (e: any, field: keyof IIntentionBlock) => {
        if (!newSubItem) return;
        const docRef = doc(db, "users", user.id, "whatsappFlows", currentItemId, "blocks", newSubItem.id);

        let check = newSubItem[field] ? newSubItem[field] : false;
        await updateDoc(docRef, { [field]: !check });
        toast.success("Saved");
    };

    return (
        <Paper>
            <Box display={"flex"} flexDirection={"column"} gap={1} p={2}>
                <Box display="flex" justifyContent="flex-start" alignItems={"center"} gap={1}>
                    <Typography sx={{ width: "100px" }}>Title</Typography>
                    <TextField
                        sx={{ flex: 1 }}
                        size="small"
                        fullWidth
                        value={newSubItem?.title || ""}
                        onChange={(e: any) => handleChange(e, "title")}
                        onBlur={handleSave}
                    />
                </Box>
                <Box display="flex" justifyContent="flex-start" alignItems={"center"} gap={1}>
                    <Typography sx={{ width: "100px" }}>Description</Typography>
                    <TextField
                        sx={{ flex: 1 }}
                        size="small"
                        fullWidth
                        value={newSubItem?.description || ""}
                        onChange={(e: any) => handleChange(e, "description")}
                        onBlur={handleSave}
                    />
                </Box>
                <Box display="flex" justifyContent="flex-start" alignItems={"center"} gap={1}>
                    <Typography sx={{ width: "100px" }}>Field</Typography>
                    <TextField
                        sx={{ flex: 1 }}
                        size="small"
                        fullWidth
                        value={newSubItem?.field || ""}
                        onChange={(e: any) => handleChange(e, "field")}
                        onBlur={handleSave}
                    />
                </Box>
                <Box display="flex" justifyContent="flex-start" alignItems={"center"} gap={1}>
                    <Typography sx={{ width: "100px" }}>Intention Type</Typography>
                    <Select
                        size="small"
                        sx={{ flex: 1 }}
                        fullWidth
                        value={newSubItem?.intentionType || "message"}
                        onChange={(e: any) => handleChange(e, "intentionType")}
                        onBlur={handleSave}
                        MenuProps={{
                            sx: { zIndex: 9999 },
                        }}
                    >
                        {intentionTypes?.map((intentionType) => (
                            <MenuItem key={intentionType} value={intentionType}>
                                {intentionType}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>

                <Box display="flex" justifyContent="flex-start" alignItems={"center"} gap={1}>
                    <Typography sx={{ width: "100px" }}>Catalog Title</Typography>
                    <Select
                        size="small"
                        sx={{ flex: 1 }}
                        fullWidth
                        value={newSubItem?.catalogId || ""}
                        onChange={(e: any) => handleChange(e, "catalogId")}
                        onBlur={handleSave}
                        MenuProps={{
                            sx: { zIndex: 9999 },
                        }}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {catalogs?.map((catalog: any) => (
                            <MenuItem key={catalog.id} value={catalog.id}>
                                {catalog.title || "Untitled Catalog"}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>

                <Box display="flex" justifyContent="flex-start" alignItems={"center"} gap={1}>
                    <Typography sx={{ width: "100px" }}>System Prompt</Typography>
                    <TextField
                        sx={{ flex: 1 }}
                        size="small"
                        fullWidth
                        rows={7}
                        multiline
                        value={newSubItem?.systemPrompt || ""}
                        onChange={(e: any) => handleChange(e, "systemPrompt")}
                        onBlur={handleSave}
                    />
                </Box>
                <Box display="flex" justifyContent="flex-start" alignItems={"center"} gap={1}>
                    <Typography sx={{ width: "100px" }}>User Prompt</Typography>
                    <TextField
                        sx={{ flex: 1 }}
                        size="small"
                        fullWidth
                        rows={7}
                        multiline
                        value={newSubItem?.userPrompt || ""}
                        onChange={(e: any) => handleChange(e, "userPrompt")}
                        onBlur={handleSave}
                    />
                </Box>
                <Box display="flex" justifyContent="flex-start" alignItems={"center"} gap={1}>
                    <Typography sx={{ width: "100px" }}>Success Prompt</Typography>
                    <TextField
                        sx={{ flex: 1 }}
                        size="small"
                        fullWidth
                        rows={5}
                        multiline
                        value={newSubItem?.thankyouPrompt || ""}
                        onChange={(e: any) => handleChange(e, "thankyouPrompt")}
                        onBlur={handleSave}
                    />
                </Box>
                <Box display="flex" justifyContent="flex-start" alignItems={"center"} gap={1}>
                    <Typography sx={{ width: "100px" }}>Error Prompt</Typography>
                    <TextField
                        sx={{ flex: 1 }}
                        size="small"
                        fullWidth
                        rows={5}
                        multiline
                        value={newSubItem?.errorPrompt || ""}
                        onChange={(e: any) => handleChange(e, "errorPrompt")}
                        onBlur={handleSave}
                    />
                </Box>

                <Box display="flex" justifyContent={"flex-start"} alignItems={"center"} gap={1}>
                    <Typography sx={{ width: "150px" }}>Execute Next Block</Typography>
                    <Switch
                        onChange={(e) => handleChangeSwith(e, "executeNextBlock")}
                        checked={
                            newSubItem?.executeNextBlock === undefined ? false : newSubItem.executeNextBlock
                        }
                    />
                </Box>
                <Box display="flex" justifyContent={"flex-start"} alignItems={"center"} gap={1}>
                    <Typography sx={{ width: "150px" }}>Required Chat History</Typography>
                    <Switch
                        onChange={(e) => handleChangeSwith(e, "requiredChatHistory")}
                        checked={
                            newSubItem?.requiredChatHistory === undefined
                                ? false
                                : newSubItem.requiredChatHistory
                        }
                    />
                </Box>
                <Box display="flex" justifyContent={"flex-start"} alignItems={"center"} gap={1}>
                    <Typography sx={{ width: "150px" }}>Allow Repeat</Typography>
                    <Switch
                        onChange={(e) => handleChangeSwith(e, "allowRepeat")}
                        checked={newSubItem?.allowRepeat === undefined ? false : newSubItem.allowRepeat}
                    />
                </Box>
            </Box>
        </Paper>
    );
}
